import { DeleteParams, ReadParams, Response, UpdateParams } from '../interfaces';
import { makeRequest } from '../makeRequest';
import { HTTP_ENDPOINTS, HTTP_METHODS, RESPONSE_FIELDS } from '../../utility/constants';


export interface Record {
    id: number,
    userId: number,
    userName: string,
    organisationId: number,
    organisationName: string,
    visibleToAdmin: boolean,
    name: string,
    fileName: string,
    fileExtension: string,
    uploadDate: string,
    frameCount: number,
    analysisDuration: number,
    modelVersion: string,
    completed: boolean,
    fakeChance: number,
    error: string,
    message?: string | number | null,
    urls: string[],
    approved?: number,
}

interface CreateParams {
    userId: number,
    organisationId: number,
    visibleToAdmin: boolean,
    name: string,
    fileName: string,
    fileExtension: string,
    isVideo: boolean,
    fileURL: string,
    createExplainable: boolean,
    startSecond: number,
    endSecond: number,
}

interface SearchValues {
    userId?: number,
    userName?: string,
    organisationId?: number,
    organisationName?: string,
    visibleToAdmin?: boolean,
    name?: string,
    fileExtension?: string,
    modelVersion?: string,
    completed?: boolean,
}

interface UpdateValues {
    userId?: number,
    visibleToAdmin?: boolean,
    name?: string,
    approved?: number,
}

export async function recordsCreate({
    userId,
    organisationId,
    visibleToAdmin,
    name,
    fileName,
    fileExtension,
    isVideo,
    fileURL,
    createExplainable = false,
    startSecond,
    endSecond,
}: CreateParams): Promise<Response<Record[]>> {
    const url = HTTP_ENDPOINTS.RECORDS;
    const data = {
        userId: userId,
        organisationId: organisationId,
        visibleToAdmin: visibleToAdmin,
        name: name,
        fileName: fileName,
        fileExtension: fileExtension,
        isVideo: isVideo,
        fileURL: fileURL,
        createExplainable: createExplainable,
        startSecond: startSecond,
        endSecond: endSecond,
    };

    return makeRequest(url, data, HTTP_METHODS.POST, RESPONSE_FIELDS.RECORDS);
}

export async function recordsRead({
    id = undefined,
    search = {},
    orderFields = undefined,
    limit = undefined,
    offset = undefined,
}: ReadParams<SearchValues>): Promise<Response<Record[]>> {
    const url = (id !== undefined) ? `${HTTP_ENDPOINTS.RECORDS}/${id}` : HTTP_ENDPOINTS.RECORDS;
    const data = {
        ...search.userId && { 'userId': search.userId },
        ...search.userName && { 'userName': search.userName },
        ...search.organisationId && { 'organisationId': search.organisationId },
        ...search.organisationName && { 'organisationName': search.organisationName },
        ...(search.visibleToAdmin !== undefined) && { 'visibleToAdmin': search.visibleToAdmin },
        ...search.name && { 'fileName': search.name },
        ...search.fileExtension && { 'fileExtension': search.fileExtension },
        ...search.modelVersion && { 'modelVersion': search.modelVersion },
        ...(search.completed !== undefined) && { 'completed': search.completed },
        ...orderFields && { 'order': orderFields},
        ...limit && { 'limit': limit },
        ...(offset !== undefined) && { 'offset': offset },
    };

    return makeRequest(url, data, HTTP_METHODS.GET, RESPONSE_FIELDS.RECORDS);
}

export async function recordsUpdate({
    id,
    values = {},
}: UpdateParams<UpdateValues>): Promise<Response<Record[]>> {
    const url = `${HTTP_ENDPOINTS.RECORDS}/${id}`;
    const data = {
        ...values.userId && { 'userId': values.userId },
        ...(values.visibleToAdmin !== undefined) && { 'visibleToAdmin': values.visibleToAdmin },
        ...values.name && { 'name': values.name },
        ...(values.approved !== undefined && values.approved !== null) && { 'approved': values.approved }
    };

    return makeRequest(url, data, HTTP_METHODS.PUT, RESPONSE_FIELDS.RECORDS);
}

export async function recordsDelete({
    id,
}: DeleteParams): Promise<Response<Record[]>> {
    const url = `${HTTP_ENDPOINTS.RECORDS}/${id}`;
    
    return makeRequest(url, {}, HTTP_METHODS.DELETE, RESPONSE_FIELDS.RECORDS);
}
