import { ChangeEvent, Fragment, SyntheticEvent, useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogTitle, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { GridBackButton, GridCheckBox, GridTextField, GridTitle, TableTitleGrid } from './inputs';
import { Table, Fields, Order } from '../tables';
import { Organisation, OrganisationCredit, organisationCreditCreate, organisationsCreate, organisationsDelete, organisationsRead, organisationsUpdate, Permissions, roles } from "../../../api/endpoints";
import { Response } from "../../../api/interfaces";
import { orderMapping } from '../../../api/orderMapping';
import { MESSAGE_OK, PATHS } from "../../../utility/constants";
import { Settings } from '../../../settings';
import { RequestAlert } from './messages';
import { requestResultParser } from '../../../utility';


interface OrganisationContainerProps {
    permissions: Permissions,
    isAdd?: boolean,
    settings: Settings,
}

interface InputState {
    name: string,
    email: string,
    enforceMFA: boolean,
    overwrite: boolean,
    credits: string,
}

const emptyInput: InputState = {
    name: '',
    email: '',
    enforceMFA: false,
    overwrite: false,
    credits: '',
};

/**
 * Render function that supplies the content for the organisation page
 * @param {Permissions}     OrganisationContainerProps.permissions
 *                          The user's permission data
 * @param {boolean}         OrganisationContainerProps.isAdd
 *                          Whether the content should contain UI to add an entry to the database
 * @param {Settings}        OrganisationContainerProps.settings
 *                          The web app global settings
 * @returns {JSX.Element}   The resulting React Element
 */
export function OrganisationContainer(props: OrganisationContainerProps): JSX.Element {
    // Destructure props
    const permissions = props.permissions;
    const isAdmin = (permissions?.role === roles.ADMIN);
    const isAdd = (props.isAdd && isAdmin);
    const settings = props.settings;

    const _selected = Number(useParams().id);
    const selected = (!Number.isNaN(_selected)) ? _selected : 0; // 0 if nothing selected

    // Initialise states
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [count, setCount] = useState<number>(0);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<Fields>('id');
    const [page, setPage] = useState<number>(1);
    const [input, setInput] = useState<InputState>(emptyInput);
    const [message, setMessage] = useState<string>('');
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            setInput(emptyInput);
            await readOrganisation();

            if (!selected) {
                return;
            } 

            await setInputSync();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, orderBy, page, selected]);

    // Set constants
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set sync handlers
    async function setInputSync() {
        const response = await organisationsRead({id: selected});

        let message = '';
        if (response.errorMessage !== undefined && response.errorMessage !== '') {
            message = response.errorMessage;
        }
        setMessage(message);
        if (!response.response) {
            return;
        }
        
        setInput({overwrite: false, ...response.response[0], credits: input.credits});
    }

    // Set UI handlers
    function handleTextfield(prop: keyof InputState) {
        return (event: ChangeEvent<HTMLInputElement>) => {
            setInput({ ...input, [prop]: event.target.value });
        };
    }

    function handleCheckbox(prop: keyof InputState) {
        return (event: SyntheticEvent<Element, Event>, checked: boolean) => {
            setInput({ ...input, [prop]: checked });
        };
    }

    function getCredit(): number {
        const float = parseFloat(input.credits);

        if (isNaN(float) || float <= 0) {
            return 0;
        }

        return Math.floor(float);
    }

    // Set API methods
    async function addOrganisation() {
        let createResponse = await organisationsCreate(input);
        if (
            createResponse.errorMessage === 'Error: organisations object already exists'
            && input.overwrite
        ) {
            createResponse = await organisationsRead({search: {name: input.name}});
            if (createResponse.response !== undefined) {
                await organisationsUpdate({id: createResponse.response[0].id, values: input});
            }
        }

        let creditResponse: Response<OrganisationCredit[]> | undefined = undefined;
        if (createResponse.response !== undefined && getCredit() !== 0) {
            creditResponse = await organisationCreditCreate({
                organisationId: createResponse.response[0].id,
                valueOriginal: getCredit(),
                doesExpire: false,
            });
        }

        setInput(emptyInput);
        
        await readOrganisation();

        let message: string = MESSAGE_OK.ORGANISATION_CREATE;
        if (createResponse.errorMessage !== undefined && createResponse.errorMessage !== '') {
            message = createResponse.errorMessage;
        }
        if (creditResponse?.errorMessage !== undefined && creditResponse.errorMessage !== '') {
            message = creditResponse.errorMessage;
        }
        setMessage(message);
    }

    async function readOrganisation() {
        const readResponse = await organisationsRead({
            orderFields: [`${orderMapping(orderBy, 'organisations', order) }`],
            limit: settings.rowsPerPage,
            offset: (page - 1) * settings.rowsPerPage,
        });
        const responseItems = readResponse.response ?? [];
        const parsedResponse: Organisation[] = [];
        for (const item of responseItems) {
            parsedResponse.push({...item, hasEdit: true, hasDelete: true});
        }

        if (readResponse.errorMessage !== undefined && readResponse.errorMessage !== '') {
            setMessage(readResponse.errorMessage);
        }
        setOrganisations(parsedResponse || []);
        setCount(readResponse.count || 0);
    }

    async function editOrganisation() {
        const updateResponse = await organisationsUpdate({id: selected, values: input});
        await readOrganisation();

        let message: string = MESSAGE_OK.ORGANISATION_UPDATE;
        if (updateResponse.errorMessage !== undefined && updateResponse.errorMessage !== '') {
            message = updateResponse.errorMessage;
        }
        setMessage(message);
    }

    async function deleteOrganisation({id}: {id: number}) {
        const deleteResponse = await organisationsDelete({id: id});
        await readOrganisation();

        let message: string = MESSAGE_OK.ORGANISATION_DELETE;
        if (deleteResponse.errorMessage !== undefined && deleteResponse.errorMessage !== '') {
            message = deleteResponse.errorMessage;
        }
        setMessage(message);
        navigate(PATHS.ORGANISATIONS);
    }

    // Render methods

    /**
     * Renders any possible messages that occur due to REST operations
     * @returns {JSX.Element} The resulting React Element
     */
    function renderMessage(): JSX.Element {
        return (
            <RequestAlert
                messageId={requestResultParser(message)}
                error={!(/OK/.test(message))}
                setMessage={setMessage}
            />
        );
    }

    /**
     * Renders the UI for adding an entry to the database
     * @returns {JSX.Element} The resulting React Element
     */
    function renderAddContent(): JSX.Element {
        if (!isAdd) {
            return (<Fragment/>);
        }

        return (
            <Grid container spacing={2} id="add-organisation" sx={{marginBottom: 2}}>
                <GridTitle titleId='organisation.add'/>
                <GridBackButton path={`${PATHS.ORGANISATIONS}`}/>
                <GridTextField
                    labelId='organisation.field.name'
                    value={input.name}
                    onChange={handleTextfield('name')}
                />
                <GridTextField
                    labelId='organisation.field.email'
                    value={input.email}
                    onChange={handleTextfield('email')}
                />
                <GridTextField
                    labelId='organisation.field.credits'
                    value={input.credits}
                    onChange={handleTextfield('credits')}
                    numeric
                />
                {/* TODO: Implement MFA

                <GridCheckBox
                    labelId='organisation.field.mfa'
                    value={input.enforceMFA}
                    onChange={handleCheckbox('enforceMFA')}
                />
                */}
                
                <GridCheckBox
                    labelId='organisation.field.update'
                    value={input.overwrite}
                    onChange={handleCheckbox('overwrite')}
                />
                <Grid item xs={12} lg={12}>
                    <Stack direction="row" justifyContent="end">
                        <Button
                            onClick={addOrganisation}
                            variant='contained'
                            sx={{ fontSize: 14 }}
                            disabled={input.name === ''}
                        >
                            {t('organisation.add')}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        );
    }
    
    /**
     * Renders the UI for altering an entry in the database
     * @returns {JSX.Element} The resulting React Element
     */
    function renderEditContent(): JSX.Element {
        if (selected === 0) {
            return (<Fragment/>);
        }

        return (
            <Grid container spacing={2} id="edit-organisation" sx={{marginBottom: 2}}>
                <GridTitle titleId='organisation.edit'/>
                <GridBackButton path={`${PATHS.ORGANISATIONS}`}/>
                <GridTextField
                    labelId='organisation.field.name'
                    value={input.name}
                    onChange={handleTextfield('name')}
                />
                <GridTextField
                    labelId='organisation.field.email'
                    value={input.email}
                    onChange={handleTextfield('email')}
                />
                {/* TODO: Implement MFA

                <GridCheckBox
                    labelId='organisation.field.mfa'
                    value={input.enforceMFA}
                    onChange={handleCheckbox('enforceMFA')}
                />
                */}
                <Grid item xs={12} lg={12}>
                    <Stack spacing={2} direction="row" justifyContent="end">
                        <Button
                            onClick={editOrganisation}
                            variant='contained'
                            sx={{ fontSize: 14 }}
                            disabled={input.name === ''}
                        >
                            {t('organisation.edit')}
                        </Button>
                        <Button
                            color="error"
                            onClick={() => setDialogOpen(true)}
                            variant='contained'
                            sx={{ fontSize: 14 }}
                        >
                            {t('organisation.delete')}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        );
    }
    
    /**
     * Renders the deletion dialog
     * @returns {JSX.Element}           The resulting React Element
     */
    function renderDialog(): JSX.Element {
        return (
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            >
                <DialogTitle>
                    {`${t('organisation.dialog.delete')} ${input.name}?`}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>
                        {t('dialog.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            setDialogOpen(false)
                            deleteOrganisation({id: selected});
                        }}
                        autoFocus>
                        {t('dialog.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    /**
     * Renders the title shown above the table
     * @returns {JSX.Element}       The resulting React Element
     */
    function renderTableTitle(): JSX.Element {
        const buttonVisible = !isAdd && !selected;

        return (
            <TableTitleGrid
                titleId={'organisation.title'}
                labelId={'organisation.add'}
                buttonVisible={buttonVisible}
                path={`${PATHS.ORGANISATIONS}${PATHS.ADD}`}
            />
        );
    }

    /**
     * Renders the table itself
     * @returns {JSX.Element} The resulting React Element
     */
    function renderTable(): JSX.Element {
        return (
            <Table
                fields={['name', 'email', 'credits']}
                rows={organisations}
                rowsPerPage={settings.rowsPerPage}
                count={count}
                order={order}
                orderBy={orderBy}
                page={page}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setPage={setPage}
                endpoint={PATHS.ORGANISATIONS}
            />
        );
    }

    return (
        <Fragment>
            {renderMessage()}
            {renderAddContent()} 
            {renderEditContent()} 
            {renderTableTitle()}
            {renderTable()}
            {renderDialog()}
        </Fragment>
    );
}
