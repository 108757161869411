import { useEffect, useState } from 'react';
import { AmplifyUser } from '@aws-amplify/ui';
import { Amplify, Auth } from 'aws-amplify';
import awsExports from './aws-exports';

import { ThemeProvider } from '@mui/material/styles';
import { RouterProvider } from "react-router-dom";

import './themes/App.css';
import { getRouter, FullProps } from './routing';
import { AuthMain } from './components/authentication';
import { mainTheme } from './themes/mainTheme';
import { AuthState } from './utility';
import { Permissions, permissionsRead, emptyPermissions, organisationCreditRead } from './api/endpoints';
import { Settings, defaultSettings } from './settings';


Amplify.configure(awsExports);

/**
 * Render function for the web application
 * @returns 
 */
export default function App() {
    // Initialise the user states
    const [authState, setAuthState] = useState<AuthState>('SIGNIN');
    const [user, setUser] = useState<AmplifyUser | undefined>(undefined);
    const [credits, setCredits] = useState<number>(0);
    const [permissions, setPermissions] = useState<Permissions>(emptyPermissions);
    const [settings, setSettings] = useState<Settings>(defaultSettings);

    // Initialise the App
    useEffect(() => {
        (async () => {
            try {
                const userResponse: AmplifyUser | undefined = await Auth.currentAuthenticatedUser();
                setUser(userResponse);
            } catch (error) { /* The user has not been authenticated yet, nothing needs to be done */ }
        })();
    }, [authState]);

    // Get user permissions and credits
    useEffect(() => {
        (async () => {
            if (user) {
                const response = await permissionsRead();
                const newPermissions = response.response ?? emptyPermissions;
                setPermissions(newPermissions);
                await getCredits();
            }
        })();
    }, [user]);

    // Bundle props
    const props: FullProps = {
        authState: authState,
        setAuthState: setAuthState,
        credits: credits,
        refreshCredits: getCredits,
        user: user,
        setUser: setUser,
        permissions: permissions,
        settings: settings,
        setSettings: setSettings,
    }

    // API methods

    // Try to retrieve user organisation credits
    async function getCredits() {
        const creditResponse = await organisationCreditRead();
        if (creditResponse.response !== undefined) {
            setCredits(creditResponse.response[0].valueCurrent);
        }
    }
    
    // Render methods

    /**
     * Determines whether to show routed content or the login page
     * @returns {JSX.Element} The resulting React Element
     */
    function getElement(): JSX.Element {
        if (authState === 'SIGNED_IN' && user) {
            return ( <RouterProvider router={getRouter(props)} />);
        }
    
        return (<AuthMain {...props} />);
    }

    return (
        <ThemeProvider theme={mainTheme}>
            { getElement() }
        </ThemeProvider>
    );
};
