import { Response } from '../interfaces';
import { makeRequest } from '../makeRequest';
import { HTTP_ENDPOINTS, HTTP_METHODS, RESPONSE_FIELDS } from '../../utility/constants';


interface PostParams {
    contactType: string,
    contactSummary: string,
    contactDescription: string,
}

export async function contactPost({
    contactType,
    contactSummary,
    contactDescription,
}: PostParams): Promise<Response<string>> {
    const url = HTTP_ENDPOINTS.CONTACT;
    const data = {
        contactType: contactType,
        contactSummary: contactSummary,
        contactDescription: contactDescription,
    };

    return makeRequest(url, data, HTTP_METHODS.POST, RESPONSE_FIELDS.CONTACT);
}
