import { DeleteParams, ReadParams, Response, UpdateParams } from '../interfaces';
import { makeRequest } from '../makeRequest';
import { HTTP_ENDPOINTS, HTTP_METHODS, RESPONSE_FIELDS } from '../../utility/constants';


export interface Organisation {
    id: number,
    name: string,
    email: string,
    enforceMFA: boolean,
    credits: number,
    hasEdit?: boolean,
    hasDelete?: boolean,
}

interface CreateParams {
    name: string,
    email: string,
    enforceMFA: boolean,
}

interface SearchValues {
    name?: string,
    email?: string,
    enforceMFA?: boolean,
}

interface UpdateValues {
    name: string,
    email: string,
    enforceMFA: boolean,
}

export async function organisationsCreate({
    name,
    email,
    enforceMFA = false,
}: CreateParams): Promise<Response<Organisation[]>> {
    const url = HTTP_ENDPOINTS.ORGANISATIONS;
    const data = {
        name: name,
        email: email,
        enforceMFA: enforceMFA,
    };

    return makeRequest(url, data, HTTP_METHODS.POST, RESPONSE_FIELDS.ORGANISATIONS);
}

export async function organisationsRead({
    id = undefined,
    search = {},
    orderFields = undefined,
    limit = undefined,
    offset = undefined,
}: ReadParams<SearchValues>): Promise<Response<Organisation[]>> {
    const url = (id !== undefined) ? `${HTTP_ENDPOINTS.ORGANISATIONS}/${id}` : HTTP_ENDPOINTS.ORGANISATIONS;
    const data = {
        ...search.name && { 'name': search.name },
        ...search.email && { 'email': search.email },
        ...(search.enforceMFA !== undefined) && { 'enforceMFA': search.enforceMFA },
        ...orderFields && { 'order': orderFields},
        ...limit && { 'limit': limit },
        ...(offset !== undefined) && { 'offset': offset },
    };

    return makeRequest(url, data, HTTP_METHODS.GET, RESPONSE_FIELDS.ORGANISATIONS);
}

export async function organisationsUpdate({
    id,
    values,
}: UpdateParams<UpdateValues>): Promise<Response<Organisation[]>> {
    const url = `${HTTP_ENDPOINTS.ORGANISATIONS}/${id}`;
    const data = {
        ...values.name && { 'name': values.name },
        ...values.email && { 'email': values.email },
        ...(values.enforceMFA !== undefined) && { 'enforceMFA': values.enforceMFA },
    };

    return makeRequest(url, data, HTTP_METHODS.PUT, RESPONSE_FIELDS.ORGANISATIONS);
}

export async function organisationsDelete({
    id,
}: DeleteParams): Promise<Response<Organisation[]>> {
    const url = `${HTTP_ENDPOINTS.ORGANISATIONS}/${id}`;
    
    return makeRequest(url, {}, HTTP_METHODS.DELETE, RESPONSE_FIELDS.ORGANISATIONS);
}
