import { ChangeEvent, Dispatch, Fragment, SetStateAction, useState } from 'react';
import { AmplifyUser } from '@aws-amplify/ui';
import { Auth } from 'aws-amplify';

import { AuthState } from '../../../utility';
import { GridActionButton, GridChangeStateButton, GridPasswordField, GridTextField, GridTitle } from './inputs';
import { AlertError, parseErrorMessage } from './errors';

interface InputState {
    email: string,
    password: string,
    error: string,
}

const emptyInput: InputState = {
    email: '',
    password: '',
    error: '',
}

interface SignInContainerProps {
    setAuthState: Dispatch<SetStateAction<AuthState>>,
    setUser: Dispatch<SetStateAction<AmplifyUser | undefined>>,
}

/**
 * Render function that supplies the content for the signin page
 * @param {Function}        SignInContainerProps.setAuthState
 *                          State setter for authState
 * @param {Function}        SignInContainerProps.setUser
 *                          State setter for user
 * @returns {JSX.Element}   The resulting React Element
 */
export function SignInContainer(props: SignInContainerProps): JSX.Element {
    // Destructure props
    const setUser = props.setUser;
    const setAuthState = props.setAuthState;
    
    // Initialise states
    const [input, setInput] = useState<InputState>(emptyInput);

    // Set UI handlers
    function handleTextfield(prop: keyof InputState) {
        return (event: ChangeEvent<HTMLInputElement>) => {
            setInput({ ...input, [prop]: event.target.value });
        };
    }

    function handleKeyPress(event: React.KeyboardEvent<HTMLDivElement>): void {
        if (event.key === 'Enter') {
            event.target.blur();
            handleSignIn();
        }
    }

    // Set API methods
    function handleSignIn(): void {
        (async () => {
            try {
                const response: AmplifyUser | undefined = await Auth.signIn(input.email, input.password);
                setUser(response);
            } catch (error) {
                parseErrorMessage(error as Error, input, setInput);
            }
        })();
    }

    // Set render methods
    function renderError(): JSX.Element {
        if (input.error === '') {
            return (<Fragment/>);
        }

        return (
            <AlertError labelId={input.error}/>
        );
    }

    return (
        <Fragment>
            <GridTitle
                titleId='auth.signin'
            />
            <GridTextField
                labelId='auth.field.email'
                value={input.email}
                onChange={handleTextfield('email')}
            />
            <GridPasswordField
                labelId='auth.field.password'
                password={input.password}
                onChange={handleTextfield('password')}
                onKeyPress={handleKeyPress}
            />
            { renderError() }
            <GridChangeStateButton
                labelId='auth.action.toPasswordForgot'
                toAuthstate='PASSWORD_FORGOT'
                setAuthState={setAuthState}
            />
            <GridActionButton
                labelId='auth.action.signin'
                disabled={input.email === ''}
                onClick={handleSignIn}
            />
        </Fragment>
    );
}
