import { ChangeEvent, Dispatch, Fragment, MouseEvent, MouseEventHandler, SetStateAction, useEffect, useState } from 'react';
import { AmplifyUser } from '@aws-amplify/ui'; 
import { Auth } from 'aws-amplify';

import { Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { QRCodeSVG } from 'qrcode.react';

import { AuthState } from '../../../utility';

/**
 * Component that portrays the Title element contained in the input grid
 * @param props.titleId Input grid title translation id
 * @returns {JSX.Element}       The resulting React Element
 */
export function GridTitle(props: {titleId: string}): JSX.Element {
    // Destructure props
    const titleId = props.titleId;

    // Set constants
    const { t } = useTranslation();

    return (
        <Grid item xs={12} sx={{marginBottom: '6px'}}>
            <Typography variant="mulish" sx={{fontSize: 21}}>
                {t(titleId)}
            </Typography>
        </Grid>
    );
}

/**
 * Component text input for grid
 * @param props.labelId         Input grid element description translation id
 * @param props.helperTextId    (Optional) Input grid element helper text translation id
 * @param props.value           The value it should sync with
 * @param props.onChange        Callback function
 * @returns {JSX.Element}       The resulting React Element
 */
export function GridTextField(props: {
    labelId: string,
    helperTextId?: string,
    value: string,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
}): JSX.Element {
    // Destructure props
    const labelId = props.labelId;
    const helperTextId = props.helperTextId ?? undefined;
    const value = props.value;
    const onChange = props.onChange;

    // Set constants
    const { t } = useTranslation();

    return (
        <Grid item xs={12}>
            <TextField 
                fullWidth
                label={t(labelId)}
                value={value}
                onChange={onChange}
                helperText={helperTextId ? t(helperTextId) : undefined}
            />
        </Grid>
    );
}

/**
 * Component password input for grid
 * @param props.labelId         Input grid element description translation id
 * @param props.password        The password value it should sync with
 * @param props.error           Is the synced value valid?
 * @param props.formHelperText  Why is the synced value not valid
 * @param props.onChange        Callback function
 * @returns {JSX.Element}       The resulting React Element
 */
export function GridPasswordField(props: {
    labelId: string,
    password: string,
    error?: boolean,
    formHelperText?: JSX.Element,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
}): JSX.Element {
    // Destructure props
    const labelId = props.labelId;
    const password = props.password;
    const error = props.error ?? false;
    const onChange = props.onChange;
    const onKeyPress = props.onKeyPress ?? undefined;
    const formHelperText = props.formHelperText ?? <Fragment/>;
    
    // Initialise states
    const [visible, setVisible] = useState<boolean>(false);

    // Set constants
    const { t } = useTranslation();

    // Set UI handlers
    function handleMouseDownPassword(event: MouseEvent<HTMLButtonElement>): void {
        event.preventDefault();
    }

    function handleClickShowPassword() {
        setVisible(!visible);
    }

    return (
        <Grid item xs={12}>
            <FormControl fullWidth>
                <InputLabel size='small'>
                    {t(labelId)}
                </InputLabel>
                <OutlinedInput
                    label={t(labelId)}
                    type={visible ? 'text' : 'password'}
                    value={password}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {visible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    error={error}
                />
                {formHelperText}
            </FormControl>
        </Grid>
    );
}

/**
 * Component button that changes the authstate upon interaction
 * @param props.labelId         Button grid element content translation id
 * @param props.toAuthstate     Which authstate to transition to
 * @param props.setAuthState    State setter for authState
 * @returns {JSX.Element}       The resulting React Element
 */
export function GridChangeStateButton(props: {
    labelId: string,
    toAuthstate: AuthState,
    setAuthState: Dispatch<SetStateAction<AuthState>>,
}): JSX.Element {
    // Destructure props
    const labelId = props.labelId;
    const toAuthstate = props.toAuthstate;
    const setAuthState = props.setAuthState;

    // Set constants
    const { t } = useTranslation();

    return (
        <Grid item xs={12} md={6}>
            <Button
                variant='text'
                onClick={() => { setAuthState(toAuthstate) }}
            >
                {t(labelId)}
            </Button>
        </Grid>
    );
}

/**
 * Component button that changes the authstate upon interaction
 * @param props.labelId         Button grid element content translation id
 * @param props.disabled        May the button be pressed?
 * @param props.variant         What does the button look like
 * @param props.onClick         Callback function
 * @returns {JSX.Element}       The resulting React Element
 */
export function GridActionButton(props: {
    labelId: string,
    disabled?: boolean,
    variant?: 'text',
    onClick: MouseEventHandler<HTMLButtonElement>,
}): JSX.Element {
    // Destructure props
    const labelId = props.labelId;
    const disabled = props.disabled ?? false;
    const variant = props.variant ?? 'contained';
    const onClick = props.onClick;

    // Set constants
    const { t } = useTranslation();

    return (
        <Grid item xs={12} md={6}>
            <Stack
                direction="row"
                justifyContent={variant==='contained' ? 'flex-end' : 'flex-start'}
                alignItems="center"
            >
                <Button variant={variant} onClick={onClick} disabled={disabled}>
                    {t(labelId)}
                </Button>
            </Stack>
            
        </Grid>
    );
}

/**
 * Component QR Code used to set up TOTP
 * @param props.user            The logged in AWS Amplify user
 */
export function GridTOTPSetupQR(props: {
    user: AmplifyUser | undefined,
}): JSX.Element {
    const [url, setUrl] = useState('');

    useEffect(() => {
        (async () => {
            const code = await Auth.setupTOTP(props.user)
            setUrl(`otpauth://totp/AWSCognito:${props.user?.username}?secret=${code}&issuer=DDG`);
        })();
    }, [props.user]);

    return (
        <Grid item xs={12}>
            <QRCodeSVG value={url} />
        </Grid>
    );
}
