import { Dispatch, SetStateAction } from "react";
import { AmplifyUser } from '@aws-amplify/ui';

import { AppBar, Link, Toolbar, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

import { AccountDropdown } from './accountDropdown';
import { SideBarToggle } from './sideBarToggle';
import { ReactComponent as Logo } from '../../../resources/logo.svg';
import { AuthState } from '../../../utility';


interface TopBarProps {
    user: AmplifyUser | undefined,
    drawerOpen: boolean,
    setDrawerOpen: Dispatch<SetStateAction<boolean>>,
    setAuthState: Dispatch<SetStateAction<AuthState>>,
    setUser: Dispatch<SetStateAction<AmplifyUser | undefined>>,
}

/**
 * Render function for the top appbar component
 * @param {AmplifyUser?} [TopBarProps.user]     The logged in AWS Amplify user
 * @param {boolean}  TopBarProps.drawerOpen     Whether the drawer is open
 * @param {Function} TopBarProps.setAuthState   State setter for authState
 * @param {Function} TopBarProps.setDrawerOpen  State setter for drawerOpen
 * @param {Function} TopBarProps.setUser        State setter for user
 * @returns {JSX.Element}                       The resulting React Element
 */
export function TopBar(props: TopBarProps): JSX.Element {
    // Destructure props
    const user = props.user;
    const drawerOpen = props.drawerOpen;
    const setAuthState = props.setAuthState;
    const setDrawerOpen= props.setDrawerOpen;
    const setUser = props.setUser;

    // Set constants
    const { t } = useTranslation();

    return (
        <AppBar 
            position="fixed"
            elevation={0}
            sx={{ 
                width: `100%`,
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
        >
            <Toolbar >
                <SideBarToggle drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}/>
                <Link 
                    href="https://www.duckduckgoose.ai/"
                    target="_blank"
                    sx={{display:'flex', alignItems: 'center'}}
                >
                    <Logo style={{marginRight: '12px'}}/>
                    <Typography variant="bebasNeue" mt='20px'>{t('app.title')}</Typography>
                </Link>
                <Typography noWrap component="div" sx={{ flexGrow: 1 }}/>
                <AccountDropdown user={user} setAuthState={setAuthState} setUser={setUser}/>
            </Toolbar>
        </AppBar>
    );
}
