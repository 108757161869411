import { Dispatch, SetStateAction } from "react";

import { IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Menu } from '@mui/icons-material';

interface SideBarToggleProps {
    drawerOpen: boolean,
    setDrawerOpen: Dispatch<SetStateAction<boolean>>,
}

/**
 * Render function for the drawer toggle button
 * @param {boolean} SideBarToggleProps.drawerOpen      Whether the drawer is open
 * @param {Function} SideBarToggleProps.setDrawerOpen  State setter for drawerOpen
 * @returns {JSX.Element}                              The resulting React Element
 */
export function SideBarToggle(props: SideBarToggleProps): JSX.Element {
    // Destructure props
    const drawerOpen = props.drawerOpen;
    const setDrawerOpen= props.setDrawerOpen;

    // Set constants
    const theme = useTheme();

    // Set variables
    const isInvisible = useMediaQuery(theme.breakpoints.up('md'));

    // Set UI handlers
    function toggleSideBar() {
        setDrawerOpen(!drawerOpen)
    }

    return (
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleSideBar}
            edge="start"
            sx={{ 
                mr: 2,
                ...(isInvisible && { display: 'none' }),
            }}
        >
            <Menu />
        </IconButton>
    );
}
