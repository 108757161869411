import { createTheme } from "@mui/material";

// Extend interfaces with custom theme constants
// Options allow configuration using `createTheme`
declare module '@mui/material/styles' {
    // Declare custom theme namespaces
    interface Theme {
        dimensions: Dimensions,
    }
    interface ThemeOptions {
        dimensions?: Dimensions,
    }
    // Declare custom font namespaces
    interface TypographyVariants {
        bebasNeue: React.CSSProperties,
        mulish: React.CSSProperties,
        poppins: React.CSSProperties,
    }
    interface TypographyVariantsOptions {
        bebasNeue?: React.CSSProperties,
        mulish?: React.CSSProperties,
        poppins?: React.CSSProperties,
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        bebasNeue: true,
        mulish: true,
        poppins: true,
    }
}

interface Dimensions {
    drawerWidth: number,
    drawerMarginLeft: number,
    drawerSelectWidth: number,
    toolbarHeight: number,
}


export const mainTheme = createTheme({
    dimensions: {
        drawerWidth: 240,
        drawerMarginLeft: 42,
        drawerSelectWidth: 8,
        toolbarHeight: 85,
    },
    palette: {
        primary: {
            main: '#001b4d',
            light: '#01b3be',
            contrastText: '#eeeeee',
        },
        secondary: {
            main: '#eeeeee',
            light: '#ffffff',
            contrastText: '#001b4d',
        },
    },
    typography: {
        bebasNeue: {
            color: '#fff',
            fontFamily: "Bebas Neue",
            fontSize: 30,
        },
        mulish: {
            fontFamily: "Mulish",
        },
        poppins: {
            fontFamily: "Poppins",
            fontSize: 14,
        },
        button: {
            fontFamily: "Poppins",
            fontSize: 15,
            textTransform: 'none'
        }
    }
});

mainTheme.components = {
    MuiDrawer: {
        styleOverrides: {
            paper: {
                borderRightWidth: 0,
                width: mainTheme.dimensions.drawerWidth,
                boxSizing: 'border-box',
                backgroundColor: mainTheme.palette.primary.main,
            },
        },
    },
    MuiToolbar: {
        styleOverrides: { 
            root: {
                backgroundColor: mainTheme.palette.primary.main,
                height: mainTheme.dimensions.toolbarHeight,
            },
        },
    },
    MuiListItemButton: {
        styleOverrides: {
            root: {
                height: '60px',
                padding: 0,
            },
        },
    },
    MuiListItemText: {
        styleOverrides: {
            root: {
                color: mainTheme.palette.primary.contrastText,
            },
            primary: {
                fontFamily: "Poppins",
                fontSize: 14,
            },
        },
    },
    MuiListItemIcon: {
        styleOverrides: {
            root: {
                color: mainTheme.palette.primary.contrastText,
                minWidth: 0,
                marginRight: '16px',
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                fontFamily: "Mulish",
            },
        },
    },
    MuiAlert: {
        styleOverrides: {
            message: {
                fontFamily: "Poppins",
            }
        },
    },
    MuiTextField: {
        defaultProps: {
            size: 'small', 
        },
    },
    MuiOutlinedInput: {
        defaultProps: {
            size: 'small', 
        },
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                fontSize: 14,
            },
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            root: {
                fontSize: 14,
            },
            shrink: {
                fontSize: 15,
            }
        },
    },
    MuiFormControlLabel: {
        styleOverrides: {
            label: {
                fontSize: 14,
            },
        },
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                height: '41px',
            },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            root: {
                padding: "0px 16px",
            },
        },
    },
    MuiTablePagination: {
        styleOverrides: {
            toolbar: {
                background: mainTheme.palette.secondary.light,
                fontFamily: "Mulish",
            },
        },
    },
};
