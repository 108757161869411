interface messageTestBody {
    regex: RegExp,
    errorId: string,
}

/** Parses the message and tests it against `testBody` for further finegraining */
function messageParser(message: string | null | undefined, testBody: messageTestBody[]): string | undefined {
    if (message === undefined || message === null) {
        return undefined;
    }

    for (const test of testBody) {
        if (test.regex.test(message)) {
            return test.errorId;
        }
    }

    return message;
}

interface AnalysisResultParserInput {
    chance: number | null | undefined,
    error?: string | undefined,
}

/**
 * Parses the appropriate message to show the user
 * @param {number | null | undefined} AnalysisResultParserInput.chance  The deepfake probability
 * @param {string | undefined}        [AnalysisResultParserInput.error] An error if it exists
 * @returns The message shown to the user
 */
export function analysisResultParser({chance, error}: AnalysisResultParserInput): string | number | null {
    const analysisErrorTests: messageTestBody[] = [
        {regex: /No Face Detected/, errorId: 'record.error.noFaceDetected'},
        {regex: /NoFaceDetected/, errorId: 'record.error.noFaceDetected'},
        {regex: /Too Small Face Detected/, errorId: 'record.error.smallFaceDetected'},
        {regex: /File-Download/, errorId: 'record.error.serverFailed'},
        {regex: /403 Client Error/, errorId: 'record.error.serverFailed'},
        {regex: /Frame-Inference/, errorId: 'record.error.serverFailed'},
        {regex: /Video-Preprocessing/, errorId: 'record.error.serverFailed'},
        {regex: /Video-Postprocessing/, errorId: 'record.error.serverFailed'},
    ];
    const parsedError = messageParser(error, analysisErrorTests);

    if (chance === null) {
        return (parsedError === undefined ? null : parsedError);
    }

    if (chance === undefined) {
        return (parsedError === undefined ? null : parsedError);
    }

    if (chance === 0) {
        return (parsedError === undefined ? chance : parsedError);
    }
    
    return chance;
}

export function requestResultParser (message: string | undefined): string {
    const requestMessageTests: messageTestBody[] = [
        {regex: /OK_CONTACT_CREATE/, errorId: 'message.ok.contactCreate'},
        {regex: /OK_COST_UPDATE/, errorId: 'message.ok.costUpdate'},
        {regex: /OK_CREDIT_CREATE/, errorId: 'message.ok.creditCreate'},
        {regex: /OK_CREDIT_DELETE/, errorId: 'message.ok.creditDelete'},
        {regex: /OK_ORGANISATION_CREATE/, errorId: 'message.ok.organisationCreate'},
        {regex: /OK_ORGANISATION_UPDATE/, errorId: 'message.ok.organisationUpdate'},
        {regex: /OK_ORGANISATION_DELETE/, errorId: 'message.ok.organisationDelete'},
        {regex: /OK_RECORD_CREATE/, errorId: 'message.ok.recordCreate'},
        {regex: /OK_RECORD_UPDATE/, errorId: 'message.ok.recordUpdate'},
        {regex: /OK_RECORD_DELETE/, errorId: 'message.ok.recordDelete'},
        {regex: /OK_USER_CREATE/, errorId: 'message.ok.userCreate'},
        {regex: /OK_USER_UPDATE/, errorId: 'message.ok.userUpdate'},
        {regex: /OK_USER_DELETE/, errorId: 'message.ok.userDelete'},
        {regex: /(?=.*Error)(?=.*SQL syntax)/, errorId: 'message.error.sql'},
        {regex: /(?=.*Error)(?=.*MySQL query)/, errorId: 'message.error.sql'},
        {regex: /(?=.*Error)(?=.*initialise pool)/, errorId: 'message.error.initPool'},
        {regex: /(?=.*Error)(?=.*parent row)/, errorId: 'message.error.parentContraint'},
        {regex: /(?=.*Error)(?=.*child row)/, errorId: 'message.error.childContraint'},
        {regex: /(?=.*Error)(?=.*not found)/, errorId: 'message.error.entryNotFound'},
        {regex: /(?=.*Error)(?=.*is empty)/, errorId: 'message.error.requestIsEmpty'},
        {regex: /Parameter is missing/, errorId: 'message.error.paramMissing'},
        {regex: /You aren't allowed/, errorId: 'message.error.notAllowed'},
        {regex: /Resource not found/, errorId: 'message.error.resourceNotFound'},
        {regex: /not yet been implemented/, errorId: 'message.error.notImplemented'},
        {regex: /Cognito and database users do not match/, errorId: 'message.error.cognitoMismatch'},
        {regex: /Action requires activation/, errorId: 'message.error.deactivated'},
        {regex: /No credits remaining/, errorId: 'message.error.noCredits'},
        {regex: /Not enough credits/, errorId: 'message.error.notEnoughCredits'},
    ];
    return messageParser(message, requestMessageTests) ?? '';
}
