import { DeleteParams, ReadParams, Response, UpdateParams } from '../interfaces';
import { makeRequest } from '../makeRequest';
import { HTTP_ENDPOINTS, HTTP_METHODS, RESPONSE_FIELDS } from '../../utility/constants';


export interface Cost {
    id: number,
    name: string,
    cost: number,
}

interface CreateParams {
    name: string,
    cost: number,
}

interface SearchValues {
    name?: string,
    cost?: number,
}

interface UpdateValues {
    name?: string,
    cost?: number,
}

export async function costsCreate({
    name,
    cost,
}: CreateParams): Promise<Response<Cost[]>> {
    const url = HTTP_ENDPOINTS.COSTS;
    const data = {
        name: name,
        cost: cost,
    };

    return makeRequest(url, data, HTTP_METHODS.POST, RESPONSE_FIELDS.COSTS);
}

export async function costsRead({
    id = undefined,
    search = {},
    orderFields = undefined,
    limit = undefined,
    offset = undefined,
}: ReadParams<SearchValues>): Promise<Response<Cost[]>> {
    const url = (id !== undefined) ? `${HTTP_ENDPOINTS.COSTS}/${id}` : HTTP_ENDPOINTS.COSTS;
    const data = {
        ...search.name && { 'name': search.name },
        ...search.cost && { 'cost': search.cost },
        ...orderFields && { 'order': orderFields},
        ...limit && { 'limit': limit },
        ...(offset !== undefined) && { 'offset': offset },
    };

    return makeRequest(url, data, HTTP_METHODS.GET, RESPONSE_FIELDS.COSTS);
}

export async function costsUpdate({
    id,
    values = {},
}: UpdateParams<UpdateValues>): Promise<Response<Cost[]>> {
    const url = `${HTTP_ENDPOINTS.COSTS}/${id}`;
    const data = {
        ...values.name && { 'name': values.name },
        ...values.cost && { 'cost': values.cost },
    };

    return makeRequest(url, data, HTTP_METHODS.PUT, RESPONSE_FIELDS.COSTS);
}

export async function costsDelete({
    id,
}: DeleteParams): Promise<Response<Cost[]>> {
    const url = `${HTTP_ENDPOINTS.COSTS}/${id}`;
    
    return makeRequest(url, {}, HTTP_METHODS.DELETE, RESPONSE_FIELDS.COSTS);
}
