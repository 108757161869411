import { Dispatch, SetStateAction } from 'react';
import { Auth } from 'aws-amplify';
import { AmplifyUser } from '@aws-amplify/ui';

import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LogoutOutlined } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';

import { AuthState } from '../../../utility';


interface SignOutProps {
    setAuthState: Dispatch<SetStateAction<AuthState>>,
    setUser: Dispatch<SetStateAction<AmplifyUser | undefined>>,
}

export function ButtonSignOut(props: SignOutProps): JSX.Element {
    // Destructure props
    const setUser = props.setUser;
    const setAuthState = props.setAuthState;

    // Set constants
    const theme = useTheme();
    const { t } = useTranslation();

    function onClick(): void {
        (async () => {
            try {
                const response: AmplifyUser | undefined = await Auth.signOut();
                setUser(response);
                setAuthState('SIGNIN');
            } catch (error) { console.log(error) }
        })();
    }

    return (
        <Button 
            variant="contained" 
            color="secondary" 
            startIcon={<LogoutOutlined/>} 
            onClick={onClick}
            sx={{
                margin: `${theme.dimensions.drawerMarginLeft}px`,
                marginTop: '16px',
                borderRadius: '14px',
                height: '42px',
                width: `${theme.dimensions.drawerWidth - 2 * theme.dimensions.drawerMarginLeft}px`,
            }}
        >
            {t('auth.logout')}
        </Button>
    );
}
