import { ChangeEvent, SyntheticEvent } from "react";

import { Button, Checkbox, FormControlLabel, Grid, Slider, Stack, TextField, Typography } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Dayjs } from "dayjs";

/**
 * Component that portrays the Title element contained in the input grid
 * @param props.titleId Input grid title translation id
 * @returns {JSX.Element}       The resulting React Element
 */
export function GridTitle(props: {titleId: string}): JSX.Element {
    // Destructure props
    const titleId = props.titleId;

    // Set constants
    const { t } = useTranslation();

    return (
        <Grid item xs={9} lg={9} sx={{marginBottom: '6px'}}>
            <Typography variant="mulish" sx={{fontSize: 21}}>
                {t(titleId)}
            </Typography>
        </Grid>
    );
}

/**
 * Component button that links back to table's main page
 * @param props.path    Absolute site path to go to
 * @returns {JSX.Element}       The resulting React Element
 */
export function GridBackButton(props: {path: string}): JSX.Element {
    // Destructure props
    const path = props.path;

    // Set constants
    const { t } = useTranslation();

    return (
        <Grid item xs={3} lg={3}>
            <Stack direction="row" justifyContent="end">
                <Button
                    component={Link} to={path}
                    sx={{ fontSize: 14 }}
                >
                    {t('nav.back')}
                </Button>
            </Stack>
        </Grid>
    );
}

/**
 * Component text input for grid
 * @param props.labelId         Input grid element description translation id
 * @param props.helperTextId    (Optional) Input grid element helper text translation id
 * @param props.value           The value it should sync with
 * @param props.onChange        Callback function
 * @param props.multiLine       Whether the component should be multiline
 * @param props.fullWidth       Whether the component should be full grid width
 * @param props.numeric         Whether the component should be numeric
 * @param props.disabled        Whether the component should be disabled
 * @returns {JSX.Element}       The resulting React Element
 */
export function GridTextField(props: {
    labelId: string,
    helperTextId?: string,
    value: string,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    multiLine?: boolean,
    fullWidth?: boolean,
    numeric?: boolean,
    disabled?: boolean,
}): JSX.Element {
    // Destructure props
    const labelId = props.labelId;
    const helperTextId = props.helperTextId ?? undefined;
    const value = props.value;
    const onChange = props.onChange;
    const multiLine = (props.multiLine === true) ? true : false;
    const fullWidth = (props.fullWidth === true) ? true : false;
    const numeric = (props.numeric === true) ? true : false;
    const disabled = props.disabled;

    // Set constants
    const { t } = useTranslation();

    return (
        <Grid item xs={12} lg={(fullWidth) ? 9.7 : 4.85}>
            <TextField 
                fullWidth
                disabled={disabled}
                multiline={multiLine}
                minRows={(multiLine) ? 4 : 1}
                maxRows={10}
                label={t(labelId)}
                value={value}
                onChange={onChange}
                helperText={helperTextId ? t(helperTextId) : undefined}
                type={(numeric) ? 'number' : 'text'}
            />
        </Grid>
    );
}

/**
 * Component date input for grid
 * @param props.labelId         Input grid element description translation id
 * @param props.value           The value it should sync with
 * @param props.onChange        Callback function
 * @param props.disabled        Whether the component should be disabled
 * @returns {JSX.Element}       The resulting React Element
 */
export function GridDateField(props: {
    labelId: string,
    value: Dayjs,
    onChange: (newValue: Dayjs | null) => void,
    disabled?: boolean,
}): JSX.Element {
    // Destructure props
    const labelId = props.labelId;
    const value = props.value;
    const onChange = props.onChange;
    const disabled = props.disabled;

    // Set constants
    const { t } = useTranslation();

    return (
        <Grid item xs={12} lg={4.85}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                    disabled={disabled}
                    label={t(labelId)}
                    inputFormat="DD/MM/YYYY"
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                />
            </LocalizationProvider>
        </Grid>
    );
}

/**
 * Component checkbox for grid
 * @param props.labelId         Input grid element description translation id
 * @param props.tooltipIcon     A trailing icon that should be wrapped in a tooltip
 * @param props.value           The value it should sync with
 * @param props.onChange        Callback function
 * @param props.disabled        Whether the component should be disabled
 * @returns {JSX.Element}       The resulting React Element
 */
export function GridCheckBox(props: {
    labelId: string,
    tooltipIcon?: JSX.Element,
    value: boolean,
    onChange: (event: SyntheticEvent<Element, Event>, checked: boolean) => void,
    disabled?: boolean,
    hidden?: boolean,
}): JSX.Element {
    // Destructure props
    const labelId = props.labelId;
    const tooltipIcon = props.tooltipIcon;
    const value = props.value;
    const onChange = props.onChange;
    const disabled = props.disabled;
    const hidden = props.hidden;

    // Set constants
    const { t } = useTranslation();

    return (
        <Grid item xs={6} lg={4.85} hidden={hidden}>
            <FormControlLabel
                label={
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <Typography sx={{ fontSize: 14 }}>
                            {t(labelId)}
                        </Typography>
                        {tooltipIcon}
                    </div>
                }
                control={
                    <Checkbox
                        disabled={disabled}
                        checked={value}
                        onChange={onChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                }
            />
        </Grid>
    );
}

export function TableTitleGrid(props: {
    titleId: string,
    labelId: string,
    buttonVisible: boolean,
    buttonDisabled?: boolean,
    path: string,
}): JSX.Element {
    // Destructure props
    const titleId = props.titleId;
    const labelId = props.labelId;
    const buttonVisible = props.buttonVisible;
    const buttonDisabled = props.buttonDisabled ?? false;
    const path = props.path;

    // Set constants
    const { t } = useTranslation();

    return(
        <Grid container spacing={2}>
            <GridTitle titleId={titleId}/>
            <Grid item xs={3} lg={3}>
                <Stack direction="row" justifyContent="end">
                    {(buttonVisible) && <Button
                        component={Link} to={path}
                        disabled={buttonDisabled}
                        variant='contained'sx={{
                            marginBottom: '15px',
                            marginRight: 0,
                            fontSize: 14,
                        }}
                    >
                        {t(labelId)}
                    </Button>}
                </Stack>
            </Grid>
        </Grid> 
    );
}

export function GridSlider(props: {
    maxDuration: number,
    analysisDuration: number[],
    setAnalysisDuration: Function,
}): JSX.Element {
    // Destructure props
    const maxDuration = props.maxDuration;
    const analysisDuration = props.analysisDuration;
    const setAnalysisDuration = props.setAnalysisDuration;

    // Set constants
    const minDuration = 1;
    const { t } = useTranslation();

    // Set UI handlers
    function handleChange(
        event: Event,
        newValue: number | number[],
        activeThumb: number,
    ) {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setAnalysisDuration([
                Math.min(newValue[0], analysisDuration[1] - minDuration),
                analysisDuration[1],
            ]);
        } else {
            setAnalysisDuration([
                analysisDuration[0],
                Math.max(newValue[1], analysisDuration[0] + minDuration),
            ]);
        }
    }

    return (
        <Grid item xs={12} lg={4.85}>
            <Typography sx={{ fontSize: 14 }}>
                {t('record.field.videoRange')}
            </Typography>
            <Slider
                getAriaLabel={() => 'grid slider'}
                value={analysisDuration}
                onChange={handleChange}
                valueLabelDisplay="auto"
                min={0}
                max={maxDuration}
                step={1}
            />
        </Grid>
    );
}
