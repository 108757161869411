import { Button, Grid, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Link, useRouteError } from "react-router-dom";

import { PATHS } from "../../utility/constants";


interface Error {
    status: number,
    statusText: string,
    message: string,
}

interface ErrorProps {
    error?: Error,
}

export function ErrorPage(props: ErrorProps) {
    // Set constants
    const routeError = useRouteError() as Error;
    const theme = useTheme();
    const { t } = useTranslation();

    // Destructure props
    const error = props.error ? props.error : routeError;

    return (
        <Grid container spacing={2} id="error-page">
            <Grid item xs={12} lg={6} style={{textAlign: "center"}}>
                <Typography variant="bebasNeue" sx={{
                    color: theme.palette.secondary.contrastText,
                    fontSize: "180px",
                }}>
                    {error.status}
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.contrastText,
                    fontSize: "29px",
                    fontFamily: "Mulish",
                    marginTop: "-30px",
                }}>
                    {error.statusText || error.message}
                </Typography>
                <Button 
                    variant="contained" 
                    color="primary" 
                    component={Link} 
                    to={PATHS.MAIN}
                    sx={{
                        margin: `${theme.dimensions.drawerMarginLeft}px`,
                        marginTop: '16px',
                        borderRadius: '14px',
                        height: '60px',
                        fontSize: '24px',
                    }}
                >
                    {t('nav.error.backToHome')}
                </Button>
            </Grid>
            <Grid item xs={12} lg={6} style={{textAlign: "center"}}>
                <img src="/assets/images/error.png" alt="error_image" />
            </Grid>
        </Grid>
    );
}
