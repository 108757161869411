import { Dispatch, Fragment, SetStateAction } from "react";
import { AmplifyUser } from "@aws-amplify/ui";

import { TokenOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { ButtonSignOut } from "../../authentication";
import { AuthState } from "../../../utility";


interface SideBarFooterProps {
    setAuthState: Dispatch<SetStateAction<AuthState>>,
    setUser: Dispatch<SetStateAction<AmplifyUser | undefined>>,
    credits: number,
}

/**
 * Render function for the sidebar's footer
 * @param {Function} SideBarProps.setAuthState  State setter for authState
 * @param {Function} SideBarProps.setUser       State setter for user
 * @returns {JSX.Element}                       The resulting React Element
 */
export function SideBarFooter(props: SideBarFooterProps): JSX.Element {
    // Destructure props
    const setUser = props.setUser;
    const setAuthState = props.setAuthState;
    const credits = props.credits;

    // Set constants
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Fragment>
            <Box sx={{
                padding: `${theme.dimensions.drawerMarginLeft}px`,
                paddingBottom: '0px',
                display: 'flex',
            }}>
                <Typography variant="poppins" sx={{color:theme.palette.primary.contrastText, flexGrow: 1}}>
                    {t('credit.remaining')}
                </Typography>
                <TokenOutlined sx={{color:theme.palette.primary.contrastText, marginTop:'-2px', paddingRight: '2px'}}/>
                <Typography variant="poppins" sx={{color:theme.palette.primary.contrastText}}>
                    {credits}
                </Typography>
            </Box>
            <ButtonSignOut setUser={setUser} setAuthState={setAuthState}/>
        </Fragment>  
    );
}
