type ObjectValues<T> = T[keyof T];

/*
 * API Constants
 */

export const HTTP_METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
} as const;
export type HttpMethods = ObjectValues<typeof HTTP_METHODS>

export const HTTP_ENDPOINTS = {
    CONTACT: '/api/contact',
    COSTS: '/api/costs',
    CREDIT_MUTATIONS: '/api/credit-mutations',
    CREDITS: '/api/credits',
    ORGANISATIONS: '/api/organisations',
    ORGANISATION_CREDITS: '/api/organisation-credits',
    PERMISSIONS: '/api/permissions',
    RECORDS: '/api/records',
    UBIOPS: '/api/ubiops',
    USERS: '/api/users',
    KEY: '/api/key',
} as const;

export const API_FUNCTIONS = {
    MAIN: 'DDWebAppAPI',
} as const;

export const RESPONSE_FIELDS = {
    CONTACT: 'message',
    COSTS: 'costs',
    CREDIT_MUTATIONS: 'creditMutations',
    CREDITS: 'credits',
    ORGANISATIONS: 'organisations',
    PERMISSIONS: 'user',
    RECORDS: 'records',
    USERS: 'users',
    KEY: 'key',
} as const;
export type ResponseFields = ObjectValues<typeof RESPONSE_FIELDS>

export const MESSAGE_OK = {
    CONTACT_CREATE: 'OK_CONTACT_CREATE',
    COST_UPDATE: 'OK_COST_UPDATE',
    CREDIT_CREATE: 'OK_CREDIT_CREATE',
    CREDIT_DELETE: 'OK_CREDIT_DELETE',
    ORGANISATION_CREATE: 'OK_ORGANISATION_CREATE',
    ORGANISATION_UPDATE: 'OK_ORGANISATION_UPDATE',
    ORGANISATION_DELETE: 'OK_ORGANISATION_DELETE',
    RECORD_CREATE: 'OK_RECORD_CREATE',
    RECORD_UPDATE: 'OK_RECORD_UPDATE',
    RECORD_DELETE: 'OK_RECORD_DELETE',
    USER_CREATE: 'OK_USER_CREATE',
    USER_UPDATE: 'OK_USER_UPDATE',
    USER_DELETE: 'OK_USER_DELETE',
} as const;

/*
 * Path constants
 */

export const PATHS = {
    MAIN: "/",
    LOGIN: "/login",
    DASHBOARD: "/dashboard",
    ORGANISATIONS: "/organisations",
    RECORDS: "/records",
    USERS: "/users",
    CREDITS: "/tokens",
    COSTS: "/costs",
    CONTACT: "/contact",
    SETTINGS: "/settings",
    FAQ: "/faq",
    ADD: "/add",
    KEY: "/key",
} as const;
export type PathValues = ObjectValues<typeof PATHS>
export type PathKeys = keyof typeof PATHS;

/*
 * Analysis constants
 */

export const analysisBoundaries = {
    NO_DEEPFAKE: 42,
    DEEPFAKE: 46,
} as const;
