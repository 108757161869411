import { Dispatch, SetStateAction } from "react";
import { AmplifyUser } from "@aws-amplify/ui";

import { Grid, Typography } from "@mui/material";

import { AuthController } from "./authController";
import { AuthState } from "../../utility";
import { ReactComponent as Logo } from '../../resources/logo_long.svg';


interface MainProps {
    authState: AuthState,
    user: AmplifyUser | undefined,
    setAuthState: Dispatch<SetStateAction<AuthState>>,
    setUser: Dispatch<SetStateAction<AmplifyUser | undefined>>,
}

/**
 * Render function for the authentication main container
 * @param {AuthState} [MainProps.authState]   The state authentication currently is in
 * @param {AmplifyUser?} [MainProps.user]     The logged in AWS Amplify user
 * @param {Function} MainProps.setAuthState   State setter for authState
 * @param {Function} MainProps.setUser        State setter for user
 * @returns {JSX.Element}                     The resulting React Element
 */
export function Main(props: MainProps): JSX.Element {
    // Destructure props
    const authState = props.authState;
    const user = props.user;
    const setAuthState = props.setAuthState;
    const setUser = props.setUser;

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
                <div style={{position: 'relative', width: '100%', height: '100%'}}>
                    <div style={{width: '100%', height: '100%', background: '#001B4D'}}/>
                    <div style={{
                        position: 'absolute',
                        left: '16px',
                        top: '16px',
                    }}>
                        <Logo/>
                    </div>
                    <div style={{
                        position: 'absolute',
                        right: '0%',
                        left: '0%',
                        bottom: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <Typography variant='bebasNeue'>Welcome to Phocus</Typography>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <AuthController
                    authState={authState}
                    user={user}
                    setAuthState={setAuthState}
                    setUser={setUser}
                />
            </Grid>
        </Grid>
    );
}
