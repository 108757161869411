import { ChangeEvent, Dispatch, DragEvent, Fragment, SetStateAction, useEffect, useState } from "react";
import { Storage } from 'aws-amplify';

import { Alert, Button, Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { InputState } from "../container/records";
import { Permissions } from "../../../api/endpoints";
import { getAllExtensions, getImageExtensions, getVideoExtensions } from "../../../utility";
import { getAudioExtensions } from "../../../utility/fileExtensions";


interface DragDropFileProps {
    permissions?: Permissions,
    input: InputState,
    setInput: Dispatch<SetStateAction<InputState>>,
}

/**
 * Render function that supplies a drag and drop element
 * @param props.permissions (Optional) The user's permission data
 * @param props.input       The input data to read from
 * @param props.setInput    Callback to set the input data
 * @returns {JSX.Element}   The resulting React Element
 */
export function DragDropFile(props: DragDropFileProps): JSX.Element {
    // Destructure props
    const permissions = props.permissions;
    const input = props.input;
    const setInput = props.setInput;

    // Initialise states
    const [dragActive, setDragActive] = useState(false);

    useEffect(() => {
        console.log(`dragActive: ${dragActive}`)
    }, [dragActive]);
    
    // Set constants
    const theme = useTheme();
    const { t } = useTranslation();

    // Set UI handlers
    function handleDrag(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (event.type === "dragenter" || event.type === "dragover") {
            setDragActive(true);
        } else if (event.type === "dragleave") {
            setDragActive(false);
        }
    }

    function handleDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        setDragActive(false);

        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            handleUpload(event.dataTransfer.files[0]);
        }
    }

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();

        if (event.target.files && event.target.files[0]) {
            handleUpload(event.target.files[0]);
        }
    }

    // Set utility methods
    function parseExtension(file: string): string {
        const fileExtension = (/(?:\.([^.]+))?$/.exec(file) ?? ['', undefined])[1];
        
        if (fileExtension === undefined) {
            setInput({
                ...input,
                error: 'record.error.extensionNotFound',
                name: '',
                fileName: '',
                fileExtension: '',
                fileURL: '',
            })
            throw Error('File extension not found');
        }

        const lowerCaseExtension = fileExtension.toLowerCase()

        const allowedExtensions = getAllExtensions();
        if (!allowedExtensions.includes(lowerCaseExtension)) {
            
            setInput({
                ...input,
                error: 'record.error.extensionNotSupported',
                name: '',
                fileName: '',
                fileExtension: '',
                fileURL: '',
            })
            throw Error('File extension not supported');
        } 

        setInput({...input, fileExtension: lowerCaseExtension});

        return lowerCaseExtension;
    }

    function parseFilename(file: string): string {
        const fileName = (/(.+?)(\.[^.]*$|$)/.exec(file) ?? ['', undefined])[1];
        
        if (fileName === undefined) throw Error('File name not found');

        return fileName;
    }

    function getDuration(file: File): Promise<number> {
        return new Promise((resolve, reject) => {
            try {
                let video = document.createElement('video')
                video.preload = 'metadata'
        
                video.onloadedmetadata = function () {
                    const duration = video.duration;
                    window.URL.revokeObjectURL(video.src);
                    resolve(duration)
                }
        
                video.onerror = function () {
                    reject("Invalid video. Please select a video file.")
                }
        
                video.src = window.URL.createObjectURL(file)
            } catch (e) {
                reject(e)
            }
        });
    }

    // Set API methods
    async function handleUpload(file: File) {
        try {
            const timestamp = Date.now();
            const fileExtension = parseExtension(file.name);

            const videoExtensions = getVideoExtensions();
            let duration = 0;
            if (videoExtensions.includes(fileExtension) || getAudioExtensions().includes(fileExtension)) {
                duration = await getDuration(file);
            }
            duration = Math.ceil(duration);

            const fileName = parseFilename(file.name);
            await Storage.put(
                `${permissions?.organisationId}/${timestamp}/original.${fileExtension}`,
                file, 
                { 
                    contentType: file.type,
                },
            );

            let generateXAI = input.generateXAI;
            if (getAudioExtensions().includes(fileExtension)) {
                generateXAI = false;
            }

            setInput({
                ...input,
                name: fileName,
                fileName: `${permissions?.organisationId}/${timestamp}`,
                fileExtension: fileExtension,
                generateXAI: generateXAI,
                fileURL: URL.createObjectURL(file),
                error: '',
                fileDuration: duration,
                analysisDuration: [0, Math.min(duration, 5)],
            });
        } catch (error) {
            console.log("Error uploading file: ", error);
        }
    }

    // Render methods
    function renderPreviewUpload(): JSX.Element {
        if (input.fileURL === '') {
            return (
                <Fragment/>
            );
        }

        const allowedExtensions = getImageExtensions();
        if (!allowedExtensions.includes(input.fileExtension)) {
            return (
                <Typography mt={1} variant="mulish">
                    {`${t('record.upload.selected')} ${input.name}.${input.fileExtension}`}
                </Typography>
            );
        }
        
        return (
            <img 
                src={input.fileURL}
                alt="preview"
                style={{
                    display: 'block',
                    maxWidth: '200px',
                    maxHeight: '200px',
                    width: 'auto',
                    height: 'auto',
                }}
            />
        );
    }

    function renderUploadInteraction(): JSX.Element {
        let textId: string;
        if (input.fileURL === '') {
            textId = 'record.upload.dnd';
        } else {
            textId = 'record.upload.anotherdnd';
        }

        return (
            <Stack
                direction="row"
                spacing={0}
                justifyContent="center"
            >
                <Typography mt={1} variant="mulish">
                    {t(textId)}
                </Typography>
                <Button
                    variant="text"
                    component="label"
                    sx={{
                        color: theme.palette.primary.light
                    }}
                >
                    {t('record.upload.browse')}
                    <input
                        type="file"
                        hidden 
                        onChange={handleChange} 
                    />
                </Button>
            </Stack>
        );
    }

    function renderError(): JSX.Element {
        if (input.error === '') {
            return (
                <Fragment/>
            );
        }

        return (
            <Alert severity="error">
                {t(input.error)}
            </Alert>
        );
    }

    return (
        <Grid item xs={12} lg={12} sx={{marginBottom: '6px'}}>
            <Paper 
                variant="outlined"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                sx={{
                    background: dragActive ? theme.palette.secondary.main : theme.palette.secondary.light,
                }}
            >
                <Stack spacing={2} justifyContent="center" alignItems="center" sx={{padding: "8px"}}>
                    {renderError()}
                    {renderPreviewUpload()}
                    {renderUploadInteraction()}
                    <Typography pb={1} align="center" variant="mulish" sx={{color: "#9897A9"}}>
                        {t('record.upload.supportedFormats')}
                    </Typography>
                </Stack>
            </Paper>
        </Grid>
    );
}
