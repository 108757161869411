

interface SideBarOrganisationProps {
}

export function SideBarOrganisation(props: SideBarOrganisationProps): JSX.Element {
    // Destructure props

    return (
        <div>

        </div>
    );
}
