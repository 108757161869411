import { Dispatch, SetStateAction, Suspense, useState } from 'react';
import { AmplifyUser } from "@aws-amplify/ui";

import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Outlet, useRouteError } from "react-router-dom";

import { SideBar } from "./sidebar/sideBar";
import { TopBar } from "./topbar/topBar";
import { ErrorPage } from "../errors";
import { AuthState } from "../../utility";
import { Permissions } from '../../api/endpoints';


interface MainProps {
    credits: number,
    permissions: Permissions,
    user: AmplifyUser | undefined,
    setAuthState: Dispatch<SetStateAction<AuthState>>,
    setUser: Dispatch<SetStateAction<AmplifyUser | undefined>>,
}

/**
 * Render function for the platform main container
 * @param {number}   MainProps.credits          How many credits the user has
 * @param {Permissions?} MainProps.permissions  The user's permission data
 * @param {AmplifyUser?} MainProps.user         The logged in AWS Amplify user
 * @param {Function} MainProps.setAuthState     State setter for authState
 * @param {Function} MainProps.setUser          State setter for user
 * @returns {JSX.Element}                       The resulting React Element
 */
export function Main(props: MainProps): JSX.Element {
    // Destructure props
    const credits = props.credits;
    const permissions = props.permissions;
    const user = props.user;
    const setUser = props.setUser;
    const setAuthState = props.setAuthState;

    // Initialise states
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    
    // Set constants
    const error = useRouteError();
    const theme = useTheme();

    // Render methods

    /**
     * Determines whether to show routed content or an error
     * @returns {JSX.Element} The resulting React Element
     */
    function getElement(): JSX.Element {
        if (error !== undefined) {
            return (<ErrorPage/>)
        }
    
        return (<Outlet />);
    }

    return (
        <Box sx={{ 
            height: '100vh',
            display: 'flex',
        }}>
            <TopBar 
                drawerOpen={drawerOpen}
                user={user}
                setAuthState={setAuthState}
                setDrawerOpen={setDrawerOpen}
                setUser={setUser}
            />
            <SideBar 
                credits={credits}
                drawerOpen={drawerOpen}
                setUser={setUser}
                setAuthState={setAuthState}
                permissions={permissions}
            />
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, mt: `${theme.dimensions.toolbarHeight}px` }}
            >
                <Suspense>
                    {getElement()}
                </Suspense> 
            </Box>
        </Box>
    );
}
