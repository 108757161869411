import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

/*  i18n Language module
    - This module enables the site to support multiple languages
    - All used strings are found under the public assets/i18n/translations
    - Current default language is English
*/
export default i18n
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: "en", 
        debug: false,
        backend: {
            /* translation file path */
            loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
        },
        ns: ["translations"],
        defaultNS: "translations",
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },
        react: {
            useSuspense: true,
        },
    });

export type Language = 
    | 'en'
;
