import { Dispatch, Fragment, SetStateAction } from 'react';

import { Paper, Table, TableContainer, Pagination, Stack } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import type { Fields, Order } from './columns';
import { TableHeader } from './tableHeader';
import { CustomTableBody as TableBody } from './tableBody';
import { PathValues } from '../../../utility/constants';


interface CustomTableProps {
    fields: Fields[],
    rows: any[],
    rowsPerPage: number,
    count: number,
    order: Order,
    orderBy: Fields,
    page: number,
    loading?: boolean,
    setOrder: Dispatch<SetStateAction<Order>>,
    setOrderBy: Dispatch<SetStateAction<Fields>>,
    setPage: Dispatch<SetStateAction<number>>,
    setRowsPerPage?: Dispatch<SetStateAction<number>>,
    setLoading?: Dispatch<SetStateAction<boolean>>,
    endpoint: PathValues,

}

/**
 * Render function that supplies the table
 * @param {Fields[]}        CustomTableProps.fields         Which fields of the data should be rendered
 * @param {any[]}           CustomTableProps.rows           The data that should populate the table
 * @param {number}          CustomTableProps.rowsPerPage    The amount of rows shown
 * @param {Function}        CustomTableProps.setRowsPerPage State setter for rowsPerPage
 * @param {number}          CustomTableProps.count          How many entries to total data contains
 * @param {Order}           CustomTableProps.order          In what direction the data should be sorted
 * @param {Fields}          CustomTableProps.orderBy        Which field the data should be sorted by
 * @param {number}          CustomTableProps.page           Which page we are currently on
 * @param {boolean}         CustomTableProps.loading        Whether the data is still loading
 * @param {Function}        CustomTableProps.setOrder       State setter for order
 * @param {Function}        CustomTableProps.setOrderBy     State setter for orderBy
 * @param {Function}        CustomTableProps.setPage        State setter for page
 * @param {PathValues}      CustomTableProps.endpoint       Which endpoint we are currently on
 * @param {Function}        CustomTableProps.deleteFunction Function that removes an entry from the database
 * @param {Function}        CustomTableProps.setLoading     State setter for loading
 * @returns {JSX.Element}                                   The resulting React Element
 */
export function CustomTable(props: CustomTableProps): JSX.Element {
    // Destructure props
    const fields = props.fields;
    const rows = props.rows;
    const rowsPerPage = props.rowsPerPage;
    const count = props.count;
    const order = props.order;
    const orderBy = props.orderBy;
    const page = props.page;
    const loading = props.loading;
    const setOrder = props.setOrder;
    const setOrderBy = props.setOrderBy;
    const setPage = props.setPage;
    const setRowsPerPage = props.setRowsPerPage;
    const setLoading = props.setLoading;
    const endpoint = props.endpoint;

    // Set UI handlers
    function handleChangePage(event: unknown, newPage: number) {
        if (typeof setLoading === "function"){
            setLoading(true);
        }
        setPage(newPage);
    };

    function handleChangeRowsPerPage(event: SelectChangeEvent<number>) {
        const newRowsPerPage = Number(event.target.value);
        if (typeof setRowsPerPage === "function") {
            setRowsPerPage(newRowsPerPage);
        }
    };

    return (
        <Fragment>
            <Paper
                variant="outlined"
                sx={{
                    width: '100%',
                    overflow: 'hidden',
                    borderRadius: '8px',
                }}
            >
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHeader
                            fields={fields}
                            order={order}
                            orderBy={orderBy}
                            setOrder={setOrder}
                            setOrderBy={setOrderBy}
                        />
                        {
                            (loading === true)?null:
                            (
                                <TableBody
                                    fields={fields}
                                    rows={rows}
                                    rowsPerPage={rowsPerPage}
                                    endpoint={endpoint}
                                />
                            )
                        }
                        
                    </Table>
                    {
                    // If data is still loading, show skeleton
                    (loading === true)?
                    (
                    <Box sx={{ width: '100%' }}>
                        {Array.from(new Array(10)).map((_, index) => (
                                <Skeleton sx={{ width: '100%', height: "40px" }} animation="wave" key={index}/>
                        ))}
                    </Box>
                    ): (rows.length === 0)? (
                        <Box sx={{ width: '100%', display: 'flex', justifyContent:'center', margin:'6px 0' }}>
                           <Typography variant="body2" gutterBottom>
                                No records found
                            </Typography>
                        </Box>
                    ): null
                    }
                </TableContainer>
            </Paper>
            <Stack direction="row" justifyContent="flex-end" alignItems='center'>
                {/* Pagination component */}
                <Pagination
                    shape="rounded"
                    showFirstButton
                    showLastButton
                    page={page}
                    count={Math.ceil(count / rowsPerPage)}
                    onChange={handleChangePage}
                    sx={{margin:'24px', align: 'right'}}
                />
                {/* Only show the rows per page selector if the setRowsPerPage function is passed */}
                {typeof setRowsPerPage ==="function" && (
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>Rows</InputLabel>
                    <Select
                        value={rowsPerPage}
                        label="Rows"
                        onChange={handleChangeRowsPerPage}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                    </Select>
                </FormControl>
                )}
            </Stack>
        </Fragment>
    );
}
