import { Fields, Order } from "../components/main/tables";
import { Tables } from "./types";

/**
 * Remaps the table colums in such a way that the backend API understands which columns to order
 * @param key       Table key that needs to be remapped
 * @param table     Which table is being operating on
 * @param direction Whether the order is ascending or descending
 * @returns         The appropriate remapped order command
 */
export function orderMapping(key: Fields, table: Tables, direction: Order): string {
    // Remap users keys to correct table version
    if (table === 'users') {
        if (key === 'name') {
            key = 'userName';
        }
        if (key === 'email' || key === 'phone') {
            return '';
        }
    }

    switch (key) {
        case 'id': return `${table}.id ${direction}`;
        case 'name': return `${table}.name ${direction}`;
        case 'email': return `${table}.email ${direction}`;
        case 'phone': return `${table}.phone ${direction}`;
        case 'userName': return `users.user_name ${direction}`;
        case 'uploadDate': return `${table}.upload_date ${direction}`;
        case 'visibleToAdmin': return `${table}.visible_to_admin ${direction}`;
        case 'organisationName': return `organisation_name ${direction}`;
        case 'enforceMFA': return `${table}.enforce_mfa ${direction}`;
        case 'fileExtension': return `${table}.file_extension ${direction}`;
        case 'message': return `${table}.fake_chance ${direction}`;
        case 'valueOriginal': return `${table}.value_original ${direction}`;
        case 'valueCurrent': return `${table}.value_current ${direction}`;
        case 'expireDate': return `${table}.expire_date ${direction}`;
        case 'cost': return `${table}.cost ${direction}`;
        default: return '';
    }
}
