import { DeleteParams, ReadParams, Response, UpdateParams } from '../interfaces';
import { makeRequest } from '../makeRequest';
import { HTTP_ENDPOINTS, HTTP_METHODS, RESPONSE_FIELDS } from '../../utility/constants';


export interface Credit {
    id: number,
    organisationId: number,
    organisationName: string,
    valueOriginal: number,
    valueCurrent: number,
    doesExpire: boolean,
    expireDate: string,
}

interface CreateParams {
    organisationId: number,
    valueOriginal: number,
    doesExpire: boolean,
    expireDate?: string,
}

interface SearchValues {
    organisationId?: number,
    organisationName?: string,
    valueOriginal?: number,
    valueCurrent?: number,
    doesExpire?: boolean,
    expireDate?: string,
}

interface UpdateValues {
    valueCurrent?: number,
}

export async function creditsCreate({
    organisationId,
    valueOriginal,
    doesExpire,
    expireDate = undefined,
}: CreateParams): Promise<Response<Credit[]>> {
    const url = HTTP_ENDPOINTS.CREDITS;
    const data = {
        organisationId: organisationId,
        valueOriginal: valueOriginal,
        doesExpire: doesExpire,
        ...expireDate && { 'expireDate': expireDate},
    };

    return makeRequest(url, data, HTTP_METHODS.POST, RESPONSE_FIELDS.CREDITS);
}

export async function creditsRead({
    id = undefined,
    search = {},
    orderFields = undefined,
    limit = undefined,
    offset = undefined,
}: ReadParams<SearchValues>): Promise<Response<Credit[]>> {
    const url = (id !== undefined) ? `${HTTP_ENDPOINTS.CREDITS}/${id}` : HTTP_ENDPOINTS.CREDITS;
    const data = {
        ...search.organisationId && { 'organisationId': search.organisationId },
        ...search.organisationName && { 'organisationName': search.organisationName },
        ...search.valueOriginal && { 'valueOriginal': search.valueOriginal },
        ...search.valueCurrent && { 'valueCurrent': search.valueCurrent },
        ...search.doesExpire && { 'doesExpire': search.doesExpire },
        ...search.expireDate && { 'expireDate': search.expireDate },
        ...orderFields && { 'order': orderFields},
        ...limit && { 'limit': limit },
        ...(offset !== undefined) && { 'offset': offset },
    };

    return makeRequest(url, data, HTTP_METHODS.GET, RESPONSE_FIELDS.CREDITS);
}

export async function creditsUpdate({
    id,
    values = {},
}: UpdateParams<UpdateValues>): Promise<Response<Credit[]>> {
    const url = `${HTTP_ENDPOINTS.CREDITS}/${id}`;
    const data = {
        ...values.valueCurrent && { 'valueCurrent': values.valueCurrent },
    };

    return makeRequest(url, data, HTTP_METHODS.PUT, RESPONSE_FIELDS.CREDITS);
}

export async function creditsDelete({
    id,
}: DeleteParams): Promise<Response<Credit[]>> {
    const url = `${HTTP_ENDPOINTS.CREDITS}/${id}`;
    
    return makeRequest(url, {}, HTTP_METHODS.DELETE, RESPONSE_FIELDS.CREDITS);
}
