import { useTheme } from '@mui/material/styles';


interface ListItemSelectProps {
    isSelected: boolean,
}

/**
 * Render function for the selection element of the sidebar menu
 * @param {boolean} ListItemSelectProps.isSelected 
 *                  Whether the element is visible 
 * @returns         The resulting React Element
 */
export function ListItemSelect(props: ListItemSelectProps): JSX.Element {
    // Destructure props
    const isSelected = props.isSelected;

    // Set constants
    const theme = useTheme();

    // Calculate dimensions
    const width = isSelected ? theme.dimensions.drawerSelectWidth : 0;
    const marginRight = theme.dimensions.drawerMarginLeft - width;

    return (
        <div 
            style={{
                width: `${width}px`,
                height: '100%',
                marginRight: `${marginRight}px`,
                background: 'linear-gradient(180deg, #01B3BE 0%, #5B4DFF 100%)',
            }}
        />
    );
}
