import { Dispatch, SetStateAction } from "react";
import { AmplifyUser } from "@aws-amplify/ui";

import { createBrowserRouter } from "react-router-dom";

import { Permissions } from "../api/endpoints";
import { ContactContainer, CostContainer, CreditContainer, Main, OrganisationContainer, RecordContainer, SettingsContainer, UserContainer, APIKeyContainer } from '../components/main';
import { PATHS } from "../utility/constants";
import { AuthState } from "../utility";
import type { Settings } from "../settings";


export interface FullProps {
    authState: AuthState,
    credits: number,
    permissions: Permissions,
    user: AmplifyUser | undefined,
    settings: Settings,
    refreshCredits: () => Promise<void>,
    setAuthState: Dispatch<SetStateAction<AuthState>>,
    setUser: Dispatch<SetStateAction<AmplifyUser | undefined>>,
    setSettings: Dispatch<SetStateAction<Settings>>,
}


/** //TODO Complete this
 * Provides a browser router for the web application
 * @param {Permissions?} [FullProps.permissions]    The user's permission data
 * @param {AmplifyUser?} [FullProps.user]           The logged in AWS Amplify user
 * @param {Function} FullProps.setAuthState         State setter for authState
 * @param {Function} FullProps.setUser              State setter for user
 * @returns The browser router used for routing the web application
 */
export function getRouter(props: FullProps) {
    const addProps = {isAdd: true, ...props};

    return createBrowserRouter([
        {
            path: PATHS.MAIN,
            element: <Main {...props}/>,
            children: [
                {
                    path: PATHS.MAIN,
                    element: <RecordContainer {...props}/>,
                },
                {
                    path: PATHS.DASHBOARD,
                    element: <RecordContainer {...props}/>,
                },
                {
                    path: PATHS.RECORDS,
                    children: [
                        {
                            path: `${PATHS.RECORDS}/${PATHS.ADD}`,
                            element: <RecordContainer {...addProps}/>,
                        },
                        {
                            path: `${PATHS.RECORDS}/:id`,
                            element: <RecordContainer {...props}/>,
                        },
                        {
                            path: `${PATHS.RECORDS}`,
                            element: <RecordContainer {...props}/>,
                        },
                    ],
                },
                {
                    path: PATHS.ORGANISATIONS,
                    children: [
                        {
                            path: `${PATHS.ORGANISATIONS}${PATHS.ADD}`,
                            element: <OrganisationContainer {...addProps}/>,
                        },
                        {
                            path: `${PATHS.ORGANISATIONS}/:id`,
                            element: <OrganisationContainer {...props}/>,
                        },
                        {
                            path: `${PATHS.ORGANISATIONS}`,
                            element: <OrganisationContainer {...props}/>,
                        },
                    ],
                },
                {
                    path: PATHS.USERS,
                    children: [
                        {
                            path: `${PATHS.USERS}${PATHS.ADD}`,
                            element: <UserContainer {...addProps}/>,
                        },
                        {
                            path: `${PATHS.USERS}/:id`,
                            element: <UserContainer {...props}/>,
                        },
                        {
                            path: `${PATHS.USERS}`,
                            element: <UserContainer {...props}/>,
                        },
                    ],
                },
                {
                    path: PATHS.CREDITS,
                    children: [
                        {
                            path: `${PATHS.CREDITS}/${PATHS.ADD}`,
                            element: <CreditContainer {...addProps}/>,
                        },
                        {
                            path: `${PATHS.CREDITS}/:id`,
                            element: <CreditContainer {...props}/>,
                        },
                        {
                            path: `${PATHS.CREDITS}`,
                            element: <CreditContainer {...props}/>,
                        },
                    ],
                },
                {
                    path: PATHS.COSTS,
                    children: [
                        {
                            path: `${PATHS.COSTS}/:id`,
                            element: <CostContainer {...props}/>,
                        },
                        {
                            path: `${PATHS.COSTS}`,
                            element: <CostContainer {...props}/>,
                        },
                    ],
                },
                {
                    path: PATHS.CONTACT,
                    element: <ContactContainer/>,
                },
                {
                    path: PATHS.SETTINGS,
                    element: <SettingsContainer/>,
                },
                {
                    path: PATHS.KEY,
                    element: <APIKeyContainer {...props}/>,
                },
            ],
            errorElement: <Main {...props}/>,
        },
    ]);
}
