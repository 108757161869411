import { Response } from '../interfaces';
import { Role } from '../types';
import { makeRequest } from '../makeRequest';
import { HTTP_ENDPOINTS, HTTP_METHODS, RESPONSE_FIELDS } from '../../utility/constants';


export interface Permissions {
    userId: number,
    userName: string,
    organisationId: number,
    organisationName: string,
    role: Role,
    deactivated: boolean,
}

export const emptyPermissions: Permissions = {
    userId: 0,
    userName: '',
    organisationId: 0,
    organisationName: '',
    role: 'employee',
    deactivated: true,
}

export const roles: {[key: string]: Role} = {
    ADMIN: 'admin',
    CLIENT: 'client',
    EMPLOYEE: 'employee',
} as const;

const permissionLevels: {[key in Role] : number} = {
    'admin': 2,
    'client': 1,
    'employee': 0,
} as const;

export function hasRequiredPermission({
    userRole,
    requiredRole,
}: {
    userRole: Role,
    requiredRole: Role,
}): boolean {
    const requiredPermissionLevel: number = permissionLevels[requiredRole];
    const actualPermissionLevel : number = permissionLevels[userRole];

    return (actualPermissionLevel >= requiredPermissionLevel);
}

export async function permissionsRead(): Promise<Response<Permissions>> {
    const url = HTTP_ENDPOINTS.PERMISSIONS;

    return makeRequest(url, {}, HTTP_METHODS.GET, RESPONSE_FIELDS.PERMISSIONS);
}
