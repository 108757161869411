import { Language } from "../utility";


export interface Settings {
    rowsPerPage: number,
    recordsPerPage: number,
    language: Language
}

export const defaultSettings: Settings = {
    rowsPerPage: 10,
    recordsPerPage: 10,
    language: 'en',
};
