import { Dispatch, Fragment, SetStateAction } from "react";

import { Alert, Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AuthState } from "../../../utility";

/**
 * Utility method that parses error to make sense for the user
 * @param error                 The error thrown by the API
 * @param input                 The input object used for the API
 * @param setInput              State setter for input
 */
export function parseErrorMessage(error: Error, input: any, setInput: SetStateAction<any>): void {
    switch (error.name) {
        case 'UserNotFoundException': 
            setInput({...input, error: 'auth.error.userNotFound'});
            break;
        case 'NotAuthorizedException': 
            setInput({...input, error: 'auth.error.notAuthorized'});
            break;
        case 'CodeMismatchException':
            setInput({...input, error: 'auth.error.codeMismatch'});
            break;
        default:
            console.log(error.name);
            console.log(error.message);
            setInput({...input, error: 'auth.error.general'});
            break;
    }
}

/**
 * Component error and button that changes the authstate to login upon interaction
 * @param props.setAuthState    State setter for authState
 * @returns {JSX.Element}       The resulting React Element
 */
export function ToLoginError(props: {
    setAuthState: Dispatch<SetStateAction<AuthState>>,
}): JSX.Element {
    // Destructure props
    const setAuthState = props.setAuthState;

    // Set constants
    const { t } = useTranslation();

    return (
        <Fragment>
            <Grid item xs={12} sx={{marginBottom: '6px'}}>
                <Typography variant="mulish" sx={{fontSize: 21}}>
                    {t('auth.error.general')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Stack
                    direction="row"
                    justifyContent='flex-end'
                    alignItems="center"
                >
                    <Button
                        variant='text'
                        onClick={() => { setAuthState('SIGNIN') }}
                    >
                        {t('auth.action.toSignin')}
                    </Button>
                </Stack>
            </Grid>
        </Fragment>
    );
}

/**
 * Component alert that shows a given error message
 * @param props.error           The parsed error thrown by the API
 * @returns {JSX.Element}       The resulting React Element
 */
export function AlertError(props: {
    labelId: string,
}): JSX.Element {
    // Destructure props
    const labelId = props.labelId;

    // Set constants
    const { t } = useTranslation();

    return (
        <Grid item xs={12}>
            <Alert severity="error">
                {t(labelId)}
            </Alert>
        </Grid>
    );
}
