import { Dispatch, SetStateAction } from 'react';
import { Auth } from 'aws-amplify';
import { AmplifyUser } from '@aws-amplify/ui';

import { ListItemIcon, MenuItem } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { LogoutOutlined } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';

import { AuthState } from '../../../utility';


interface SignOutProps {
    setAuthState: Dispatch<SetStateAction<AuthState>>,
    setUser: Dispatch<SetStateAction<AmplifyUser | undefined>>,
}

export function MenuSignOut(props: SignOutProps): JSX.Element {
    // Destructure props
    const setUser = props.setUser;
    const setAuthState = props.setAuthState;

    // Set constants
    const theme = useTheme();
    const { t } = useTranslation();

    function onClick(): void {
        (async () => {
            try {
                const response: AmplifyUser | undefined = await Auth.signOut();
                setUser(response);
                setAuthState('SIGNIN');
            } catch (error) { console.log(error) }
        })();
    }

    return (
        <MenuItem onClick={onClick}>
            <ListItemIcon sx={{marginRight:'0px'}}>
                <LogoutOutlined sx={{color:theme.palette.primary.light}}/>
            </ListItemIcon>
            {t('auth.logout')}
        </MenuItem>
    );
}
