import { Dispatch, Fragment, SetStateAction } from "react";
import { AmplifyUser } from "@aws-amplify/ui";

import { Box, Slide , Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { ListItemSelect } from "./listItemSelect";
import { SideBarOrganisation } from "./sideBarOrganisation";
import { SideBarFooter } from "./sideBarFooter";
import { DrawerMenu } from "../../../routing";
import { AuthState } from "../../../utility";
import { Permissions, hasRequiredPermission } from "../../../api/endpoints";


interface SideBarProps {    
    credits: number,
    drawerOpen: boolean,
    permissions: Permissions,
    setAuthState: Dispatch<SetStateAction<AuthState>>,
    setUser: Dispatch<SetStateAction<AmplifyUser | undefined>>,
}

/**
 * Render function for the sidebar component
 * @param {number}   SideBarProps.credits       How many credits the user has
 * @param {boolean}  SideBarProps.drawerOpen    Whether the drawer is open
 * @param {Function} SideBarProps.setAuthState  State setter for authState
 * @param {Function} SideBarProps.setUser       State setter for user
 * @returns {JSX.Element}                       The resulting React Element
 */
export function SideBar(props: SideBarProps): JSX.Element {
    // Destructure props
    const credits = props.credits;
    const drawerOpen = props.drawerOpen;
    const permissions = props.permissions;
    const setUser = props.setUser;
    const setAuthState = props.setAuthState;

    // Set constants
    const theme = useTheme();
    const { t } = useTranslation();
    const location = useLocation();
    const listItems = DrawerMenu;

    // Set variables
    const isStatic = useMediaQuery(theme.breakpoints.up('md'));
    const isInvisible = (isStatic || drawerOpen);

    return (
        <Slide
            direction="right"
            in={isInvisible}
            mountOnEnter
            unmountOnExit
        >
            <Drawer
                sx={{
                    width: theme.dimensions.drawerWidth,
                    height: '100vh',
                }}
                variant="permanent"
                anchor="left"
            >
                <Toolbar/>
                <SideBarOrganisation/>

                <List>
                    {listItems.map(item => {
                        if (!hasRequiredPermission({userRole: permissions.role, requiredRole: item.requiredRole})) {
                            return (<Fragment key={item.textId}/>);
                        }

                        return (
                            <ListItem key={item.textId} disablePadding>
                                <ListItemButton component={Link} to={item.link}>
                                    <ListItemSelect isSelected={location.pathname.includes(item.link)}/>
                                    <ListItemIcon>
                                        <item.icon />
                                    </ListItemIcon>
                                    <ListItemText primary={t(item.textId)}/>
                                </ListItemButton>
                            </ListItem>
                        );
                    })} 
                </List>

                <Box sx={{ flexGrow: 1 }}></Box>

                <SideBarFooter
                    setUser={setUser}
                    setAuthState={setAuthState}
                    credits={credits}
                />
            </Drawer>
        </Slide>
    );
}
