import { Dispatch, Fragment, SetStateAction } from "react";

import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";


interface RequestAlertProps {
    messageId: string,
    setMessage: Dispatch<SetStateAction<string>>,
    error?: boolean,
}

export function RequestAlert(props: RequestAlertProps): JSX.Element {
    // Destructure props
    const messageId = props.messageId;
    const setMessage = props.setMessage;
    const error = props.error ?? false;

    // Set constants
    const { t } = useTranslation();

    if (messageId === '') {
        return (<Fragment/>);
    }

    return (
        <Alert
            severity={error ? 'error' : 'success'}
            onClose={() => {setMessage('')}}
            sx={{marginBottom: 2}}
        >
            {t(messageId)}
        </Alert>
    );
}
