import { ChangeEvent, Fragment, useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { GridBackButton, GridTextField, GridTitle, TableTitleGrid } from './inputs';
import { Table, Fields, Order } from '../tables';
import { User, usersCreate, usersDelete, usersRead, usersUpdate, Permissions, roles, Organisation, organisationsRead, hasRequiredPermission } from "../../../api/endpoints";
import { orderMapping } from '../../../api/orderMapping';
import { MESSAGE_OK, PATHS } from "../../../utility/constants";
import { Settings } from '../../../settings';
import { RequestAlert } from './messages';
import { Role } from '../../../api/types';
import { requestResultParser } from '../../../utility';


interface UserContainerProps {
    permissions: Permissions,
    isAdd?: boolean,
    settings: Settings,
}

interface InputState {
    organisationId: number,
    name: string,
    email: string,
    phone: string,
    role: Role,
    roleId: number,
    deactivated: boolean,
    disabled: boolean,
}

const roleIndices: {[id: number]: Role} = {
    1: roles.EMPLOYEE,
    2: roles.CLIENT,
    3: roles.ADMIN,
}

const emptyInput: InputState = {
    organisationId: 1,
    name: '',
    email: '',
    phone: '',
    role: roleIndices[1],
    roleId: 1,
    deactivated: false,
    disabled: false,
};

/**
 * Render function that supplies the content for the user page
 * @param {Permissions}     UserContainerProps.permissions
 *                          The user's permission data
 * @param {boolean}         UserContainerProps.isAdd
 *                          Whether the content should contain UI to add an entry to the database
 * @param {Settings}        OrganisationContainerProps.settings
 *                          The web app global settings
 * @returns {JSX.Element}   The resulting React Element
 */
export function UserContainer(props: UserContainerProps): JSX.Element {
    // Destructure props
    const permissions = props.permissions;
    const hasPermission = hasRequiredPermission({userRole: permissions?.role, requiredRole: roles.CLIENT});
    const isAdd = (props.isAdd && hasPermission);
    const settings = props.settings;

    const _selected = Number(useParams().id);
    const selected = (!Number.isNaN(_selected)) ? _selected : 0; // 0 if nothing selected

    // Initialise states
    const [users, setUsers] = useState<User[]>([]);
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [count, setCount] = useState<number>(0);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<Fields>('id');
    const [page, setPage] = useState<number>(1);
    const [input, setInput] = useState<InputState>({...emptyInput, organisationId: permissions.organisationId});
    const [message, setMessage] = useState<string>('');
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            setInput(emptyInput);
            await readUser();
            
            await setOrganisationsSync();

            if (!selected) { 
                return;
            }

            await setInputSync();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, orderBy, page, selected]);

    // Set constants
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set sync handlers
    async function setInputSync() {
        const userResponse = await usersRead({id: selected});
        
        let message = '';
        if (userResponse.errorMessage !== undefined && userResponse.errorMessage !== '') {
            message = userResponse.errorMessage;
        }
        setMessage(message);
        if (!userResponse.response || userResponse.response === undefined) {
            return;
        }
        const notNullResponse = userResponse.response[0]

        const [roleId] = Object.entries(roleIndices).find(([key, val]) => val === notNullResponse.role) || ['1']
        setInput({...input, ...(notNullResponse), roleId: parseInt(roleId)});
    }

    async function setOrganisationsSync() {
        const organisationsResponse = await organisationsRead({});
        if(!organisationsResponse.response) {
            return;
        }

        setOrganisations(organisationsResponse.response);
    }

    // Set UI handlers
    function handleTextfield(prop: keyof InputState) {
        return (event: ChangeEvent<HTMLInputElement>) => {
            setInput({ ...input, [prop]: event.target.value });
        };
    }

    function handleSelect(prop: keyof InputState) {
        return (event: SelectChangeEvent<number>, child: React.ReactNode) => {
            const result = event.target.value as number;
            if (prop.toString() === "roleId") {
                setInput({ ...input, [prop]: result, role: roleIndices[result] });
            } else {
                setInput({ ...input, [prop]: result });
            }
        };
    }

    // Set API methods
    async function addUser() {
        const createResponse = await usersCreate(input);

        setInput(emptyInput);
        
        await readUser();
        
        let message: string = MESSAGE_OK.USER_CREATE;
        if (createResponse.errorMessage !== undefined && createResponse.errorMessage !== '') {
            message = createResponse.errorMessage;
        }
        setMessage(message);
    }

    async function readUser() {
        const readResponse = await usersRead({
            orderFields: [`${orderMapping(orderBy, 'users', order) }`],
            limit: settings.rowsPerPage,
            offset: (page - 1) * settings.rowsPerPage,
        });
        const responseItems = readResponse.response ?? [];
        const parsedResponse: User[] = [];
        for (const item of responseItems) {
            parsedResponse.push({...item, hasEdit: true});
        }

        if (readResponse.errorMessage !== undefined && readResponse.errorMessage !== '') {
            setMessage(readResponse.errorMessage);
        }
        setUsers(parsedResponse || []);
        setCount(readResponse.count || 0);
    }

    async function editUser() {
        const updateResponse = await usersUpdate({id: selected, values: input});
        await readUser();

        let message: string = MESSAGE_OK.USER_UPDATE;
        if (updateResponse.errorMessage !== undefined && updateResponse.errorMessage !== '') {
            message = updateResponse.errorMessage;
        }
        setMessage(message);
    }

    async function toggleDeactivation() {
        const currentState = input.deactivated;
        setInput({...input, deactivated: !currentState});
        await usersUpdate({id: selected, values: {...input, deactivated: !currentState}});
        await readUser();
    }

    async function toggleDisabled() {
        const currentState = input.disabled;
        setInput({...input, disabled: !currentState});
        await usersUpdate({id: selected, values: {...input, disabled: !currentState}});
        await readUser();
    }

    async function deleteUser({id}: {id: number}) {
        const deleteResponse = await usersDelete({id: id});
        await readUser();
        
        let message: string = MESSAGE_OK.USER_DELETE;
        if (deleteResponse.errorMessage !== undefined && deleteResponse.errorMessage !== '') {
            message = deleteResponse.errorMessage;
        }
        setMessage(message);
        navigate(PATHS.USERS);
    }

    // Render methods

    /**
     * Renders any possible messages that occur due to REST operations
     * @returns {JSX.Element} The resulting React Element
     */
    function renderMessage(): JSX.Element {
        return (
            <RequestAlert
                messageId={requestResultParser(message)}
                error={!(/OK/.test(message))}
                setMessage={setMessage}
            />
        );
    }

    /**
     * Renders the UI for adding an entry to the database
     * @returns {JSX.Element} The resulting React Element
     */
    function renderAddContent(): JSX.Element {
        if (!isAdd) {
            return (<Fragment/>);
        }

        return (
            <Grid container spacing={2} id="add-user" sx={{marginBottom: 2}}>
                <GridTitle titleId='user.add'/>
                <GridBackButton path={`${PATHS.USERS}`}/>
                <Grid item xs={12} lg={4.85}>
                    <FormControl fullWidth>
                    <InputLabel id="label">{t('user.field.organisation')}</InputLabel>
                    <Select
                        labelId="label"
                        fullWidth
                        size='small'
                        label={t('user.field.organisation')}
                        value={input.organisationId}
                        onChange={handleSelect('organisationId')}
                    >
                        {organisations.map(item => (
                            <MenuItem value={item.id} key={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={4.85}>
                    <FormControl fullWidth>
                        <InputLabel id="label">{t('user.field.role')}</InputLabel>
                        <Select
                            labelId="label"
                            fullWidth
                            size='small'
                            label={t('user.field.role')}
                            value={input.roleId}
                            onChange={handleSelect('roleId')}
                        >
                            {Object.keys(roleIndices).map(item => (
                                <MenuItem value={item} key={item}>
                                    {roleIndices[parseInt(item)]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <GridTextField
                    labelId='user.field.name'
                    value={input.name}
                    onChange={handleTextfield('name')}
                />
                <GridTextField
                    labelId='user.field.email'
                    value={input.email}
                    onChange={handleTextfield('email')}
                />
                <GridTextField
                    labelId='user.field.phone'
                    value={input.phone}
                    onChange={handleTextfield('phone')}
                    helperTextId='user.info.phone'
                />
                <Grid item xs={12} lg={7.15}>
                    <Stack direction="row" justifyContent="end">
                        <Button
                            onClick={addUser}
                            variant='contained'
                            sx={{ fontSize: 14 }}
                            disabled={input.name === ''}
                        >
                            {t('user.add')}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        );
    }
    
    /**
     * Renders the UI for altering an entry in the database
     * @returns {JSX.Element} The resulting React Element
     */
    function renderEditContent(): JSX.Element {
        if (selected === 0) {
            return (<Fragment/>);
        }

        return (
            <Grid container spacing={2} id="edit-user" sx={{marginBottom: 2}}>
                <GridTitle titleId='user.edit'/>
                <GridBackButton path={`${PATHS.USERS}`}/>
                <Grid item xs={12} lg={4.85}>
                    <FormControl fullWidth>
                        <InputLabel id="label">{t('user.field.organisation')}</InputLabel>
                        <Select
                            labelId="label"
                            fullWidth
                            disabled
                            size='small'
                            label={t('user.field.organisation')}
                            value={input.organisationId}
                            onChange={handleSelect('organisationId')}
                        >
                            {organisations.map(item => (
                                <MenuItem value={item.id} key={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={4.85}>
                    <FormControl fullWidth>
                        <InputLabel id="label">{t('user.field.role')}</InputLabel>
                        <Select
                            labelId="label"
                            fullWidth
                            size='small'
                            label={t('user.field.role')}
                            value={input.roleId}
                            onChange={handleSelect('roleId')}
                        >
                            {Object.keys(roleIndices).map(item => (
                                <MenuItem value={parseInt(item)} key={item}>
                                    {roleIndices[parseInt(item)]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <GridTextField
                    labelId='user.field.name'
                    value={input.name}
                    onChange={handleTextfield('name')}
                />
                <GridTextField
                    labelId='user.field.email'
                    value={input.email}
                    onChange={handleTextfield('email')}
                />
                <GridTextField
                    labelId='user.field.phone'
                    value={input.phone}
                    onChange={handleTextfield('phone')}
                    helperTextId='user.info.phone'
                />
                <Grid item xs={12} lg={12}>
                    <Stack spacing={2} direction="row" justifyContent="end">
                        <Button
                            onClick={editUser}
                            variant='contained'
                            sx={{ fontSize: 14 }}
                            disabled={input.name === ''}
                        >
                            {t('user.edit')}
                        </Button>
                        <Tooltip title={t('user.info.deactivate')}>
                            <Button
                                color="error"
                                onClick={toggleDeactivation}
                                variant='contained'
                                sx={{ fontSize: 14 }}
                            >
                                {input.deactivated ? t('user.button.activate') : t('user.button.deactivate')}
                            </Button>
                        </Tooltip>
                        <Tooltip title={t('user.info.disable')}>
                            <Button
                                color="error"
                                onClick={toggleDisabled}
                                variant='contained'
                                sx={{ fontSize: 14 }}
                            >
                                {input.disabled ? t('user.button.enable') : t('user.button.disable')}
                            </Button>
                        </Tooltip>
                        <Tooltip title={t('user.info.delete')}>
                            <Button
                                color="error"
                                onClick={() => setDialogOpen(true)}
                                variant='contained'
                                sx={{ fontSize: 14 }}
                                disabled={!input.disabled}
                            >
                                {t('user.delete')}
                            </Button>
                        </Tooltip>
                    </Stack>
                </Grid>
            </Grid>
        );
    }
    
    /**
     * Renders the deletion dialog
     * @returns {JSX.Element}           The resulting React Element
     */
    function renderDialog(): JSX.Element {
        return (
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            >
                <DialogTitle>
                    {`${t('user.dialog.delete')} ${input.name}?`}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>
                        {t('dialog.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            setDialogOpen(false)
                            deleteUser({id: selected});
                        }}
                        autoFocus>
                        {t('dialog.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    /**
     * Renders the title shown above the table
     * @returns {JSX.Element}       The resulting React Element
     */
    function renderTableTitle(): JSX.Element {
        const buttonVisible = !isAdd && !selected;

        return (
            <TableTitleGrid
                titleId={'user.title'}
                labelId={'user.add'}
                buttonVisible={buttonVisible}
                path={`${PATHS.USERS}${PATHS.ADD}`}
            />
        );
    }

    /**
     * Renders the table itself
     * @returns {JSX.Element} The resulting React Element
     */
    function renderTable(): JSX.Element {
        return (
            <Table
                fields={['organisationName', 'name', 'email', 'phone']}
                rows={users}
                rowsPerPage={settings.rowsPerPage}
                count={count}
                order={order}
                orderBy={orderBy}
                page={page}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setPage={setPage}
                endpoint={PATHS.USERS}
            />
        );
    }

    return (
        <Fragment>
            {renderMessage()}
            {renderAddContent()} 
            {renderEditContent()} 
            {renderTableTitle()}
            {renderTable()}
            {renderDialog()}
        </Fragment>
    );
}
