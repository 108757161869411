import { DeleteParams, ReadParams, Response, UpdateParams } from '../interfaces';
import { Role } from '../types';
import { makeRequest } from '../makeRequest';
import { HTTP_ENDPOINTS, HTTP_METHODS, RESPONSE_FIELDS } from '../../utility/constants';


export interface User {
    id: number,
    organisationId: number,
    organisationName: string,
    name: string,
    email: string,
    phone: string,
    role: Role,
    usernameCognito: string,
    tokenPrefix: string,
    deactivated: boolean,
    disabled: boolean,
    mfaOptions: any,
    hasEdit?: boolean,
}

interface CreateParams {
    organisationId: number,
    name: string,
    email: string,
    phone: string,
    role: Role,
}

interface SearchValues {
    organisationId?: number,
    organisationName?: string,
    name?: string,
    role?: Role,
    deactivated?: boolean,
    disabled?: boolean,
}

interface UpdateValues {
    name?: string,
    role?: Role,
    email?: string,
    phone?: string,
    deactivated?: boolean,
    disabled?: boolean,
}

export async function usersCreate({
    organisationId,
    name,
    email,
    phone,
    role,
}: CreateParams): Promise<Response<User[]>> {
    const url = HTTP_ENDPOINTS.USERS;
    const data = {
        organisationId: organisationId,
        name: name,
        email: email,
        phone: phone,
        role: role,
    };

    return makeRequest(url, data, HTTP_METHODS.POST, RESPONSE_FIELDS.USERS);
}

export async function usersRead({
    id = undefined,
    search = {},
    orderFields = undefined,
    limit = undefined,
    offset = undefined,
}: ReadParams<SearchValues>): Promise<Response<User[]>> {
    const url = (id !== undefined) ? `${HTTP_ENDPOINTS.USERS}/${id}` : HTTP_ENDPOINTS.USERS;
    const data = {
        ...search.organisationId && { 'organisationId': search.organisationId },
        ...search.organisationName && { 'organisationName': search.organisationName },
        ...search.name && { 'name': search.name },
        ...search.role && { 'role': search.role },
        ...(search.deactivated !== undefined) && { 'deactivated': search.deactivated },
        ...(search.disabled !== undefined) && { 'disabled': search.disabled },
        ...orderFields && { 'order': orderFields},
        ...limit && { 'limit': limit },
        ...(offset !== undefined) && { 'offset': offset },
    };

    return makeRequest(url, data, HTTP_METHODS.GET, RESPONSE_FIELDS.USERS);
}

export async function usersUpdate({
    id,
    values = {},
}: UpdateParams<UpdateValues>): Promise<Response<User[]>> {
    const url = `${HTTP_ENDPOINTS.USERS}/${id}`;
    const data = {
        ...values.name && { 'name': values.name },
        ...values.role && { 'role': values.role },
        ...values.email && { 'email': values.email },
        ...values.phone && { 'phone': values.phone },
        ...(values.deactivated !== undefined) && { 'deactivated': values.deactivated },
        ...(values.disabled !== undefined) && { 'disabled': values.disabled },
    };

    return makeRequest(url, data, HTTP_METHODS.PUT, RESPONSE_FIELDS.USERS);
}

export async function usersDelete({
    id,
}: DeleteParams): Promise<Response<User[]>> {
    const url = `${HTTP_ENDPOINTS.USERS}/${id}`;
    
    return makeRequest(url, {}, HTTP_METHODS.DELETE, RESPONSE_FIELDS.USERS);
}
