import { ChangeEvent, Fragment, useState } from "react";

import { Button, Grid, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import { GridTextField, GridTitle } from "./inputs";
import { RequestAlert } from "./messages";
import { contactPost } from "../../../api/endpoints";
import { requestResultParser } from "../../../utility";
import { MESSAGE_OK } from "../../../utility/constants";

const types: {[id: number]: string} = {
    1: 'contact.type.support',
    2: 'contact.type.bug',
    3: 'contact.type.suggestion',
    4: 'contact.type.remainder',
} as const;

interface InputState {
    type: number,
    summary: string,
    description: string,
}

const emptyInput: InputState = {
    type: 0,
    summary: '',
    description: '',
};

/**
 * Render function that supplies the content for the contact page
 * @returns {JSX.Element}   The resulting React Element
 */
export function ContactContainer(): JSX.Element {
    // Initialise states
    const [input, setInput] = useState<InputState>(emptyInput);
    const [message, setMessage] = useState<string>('');

    // Set constants
    const { t } = useTranslation();

    // Set UI handlers
    function handleTextfield(prop: keyof InputState) {
        return (event: ChangeEvent<HTMLInputElement>) => {
            setInput({ ...input, [prop]: event.target.value });
        };
    }

    function handleSelect(prop: keyof InputState) {
        return (event: SelectChangeEvent<number>, child: React.ReactNode) => {
            const result = event.target.value as number;
            setInput({ ...input, [prop]: result });
        };
    }

    // Set API methods
    async function postContact() {
        const createResponse = await contactPost({
            contactType: types[input.type],
            contactSummary: input.summary,
            contactDescription: input.description,
        });
        setInput(emptyInput);
        
        let message: string = MESSAGE_OK.CONTACT_CREATE;
        if (createResponse.errorMessage !== undefined && createResponse.errorMessage !== '') {
            message = createResponse.errorMessage;
        }
        setMessage(message);
    }

    // Render methods

    /**
     * Renders any possible messages that occur due to REST operations
     * @returns {JSX.Element} The resulting React Element
     */
    function renderMessage(): JSX.Element {
        return (
            <RequestAlert
                messageId={requestResultParser(message)}
                error={!(/OK/.test(message))}
                setMessage={setMessage}
            />
        );
    }

    function renderAddContent(): JSX.Element {
        return (
            <Grid container spacing={2} id="post-contact">
                <GridTitle titleId='contact.post'/>
                <Grid item xs={12} lg={4.85}>
                    <Select
                        fullWidth
                        size='small'
                        label={t('contact.field.type')}
                        value={input.type}
                        onChange={handleSelect('type')}
                    >
                        {Object.keys(types).map(item => (
                            <MenuItem value={item} key={item}>
                                {t(types[parseInt(item)])}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <GridTextField
                    labelId='contact.field.summary'
                    value={input.summary}
                    onChange={handleTextfield('summary')}
                />
                <GridTextField
                    labelId='contact.field.description'
                    value={input.description}
                    onChange={handleTextfield('description')}
                    multiLine
                    fullWidth
                />
                <Grid item xs={12} lg={2.3}>
                    <Stack sx={{height: "100%"}} direction="row" justifyContent="end" alignItems="end">
                        <Button
                            onClick={postContact}
                            variant='contained'
                            sx={{ fontSize: 14 }}
                            disabled={
                                input.type === 0 || input.summary === '' || input.description === ''}
                        >
                            {t('contact.post')}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        );
    }
    
    return (
        <Fragment>
            {renderMessage()}
            {renderAddContent()}
        </Fragment>
    );
}
