import { API } from 'aws-amplify';

import { API_FUNCTIONS, HTTP_METHODS, HttpMethods, ResponseFields } from '../utility/constants';
import { Response } from './interfaces';


class UnknownMethodError extends Error {
    response: any;

    constructor(message: string, response: any) {
        super(message);
        this.response = response;
    }
}

/**
 * Parses an API response and extracts the data of type Type
 * @param method        Which method was called
 * @param response      The API response
 * @param responseField Which field to extract
 * @returns             The parsed response
 */
function parser<Type>(
    method: HttpMethods,
    response: any,
    responseField: ResponseFields,
): Response<Type> {
    const data = (response.data !== undefined) ? response.data : {};
    data.error = (data.error) ? data.error : "";
    return {
        response: data[responseField],
        errorMessage: data.error,
        count: data.count,
        method: method,
        success: (response.status === 200),
        status: response.status,
    }
}

/**
 * Calls the API for CRUD methods
 * @type            Which generic type to return
 * @param url       Which endpoint in the API to call
 * @param data      The data required to make the call
 * @param method    Which CRUD method to use on the call
 * @param field     Which field to parse the response of
 * @returns         API response parsed to type
 */
export async function makeRequest<Type>(
    url: string,
    data: object,
    method: HttpMethods,
    field: ResponseFields,
): Promise<Response<Type>> {
    let response: any;
    try {
        switch(method) {
            case HTTP_METHODS.GET:
                response = await API.get(API_FUNCTIONS.MAIN, url, {
                    response: true,
                    queryStringParameters: data,
                });
                break;
            case HTTP_METHODS.POST:
                response = await API.post(API_FUNCTIONS.MAIN, url, {
                    response: true,
                    body: data,
                });
                break;
            case HTTP_METHODS.PUT:
                response = await API.put(API_FUNCTIONS.MAIN, url, {
                    response: true,
                    body: data,
                });
                break;
            case HTTP_METHODS.DELETE:
                response = await API.del(API_FUNCTIONS.MAIN, url, {
                    response: true,
                    body: data,
                });
                break;
            default:
                const message = "This method is not known";
                throw new UnknownMethodError(message, {
                    data: {errorMessage: message},
                    status: 405,
                });
        }
    } catch (error: any) {
        response = error.response;
    }

    return parser<Type>(method, response, field);
}
