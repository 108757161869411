import { HelpOutlineOutlined, TokenOutlined, EmailOutlined } from "@mui/icons-material";
import { OverridableComponent } from "@mui/types";
import { SvgIconTypeMap } from "@mui/material";

import { PATHS, PathValues } from "../../utility/constants";

export const AccountDropdownMenu: {
    link: PathValues,
    textId: string,
    icon: OverridableComponent<SvgIconTypeMap>
}[] = [
    {
        link: PATHS.CONTACT,
        textId: 'nav.menu.support',
        icon: EmailOutlined,
    },
    {
        link: PATHS.CREDITS,
        textId: 'nav.credits',
        icon: TokenOutlined,
    },
    // {
    //     link: PATHS.FAQ,
    //     textId: 'nav.menu.faq',
    //     icon: HelpOutlineOutlined,
    // },
]
