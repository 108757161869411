import { Dispatch, Fragment, SetStateAction, useEffect } from 'react';
import { AmplifyUser } from '@aws-amplify/ui';

import { Grid } from '@mui/material';

import { MFAContainer, PasswordContainer, SignInContainer } from './container';
import { ToLoginError } from './container/errors';
import { AuthState, ChallengeNameState } from '../../utility';


interface AuthControllerProps {
    authState: AuthState,
    user: AmplifyUser | undefined,
    setAuthState: Dispatch<SetStateAction<AuthState>>,
    setUser: Dispatch<SetStateAction<AmplifyUser | undefined>>,
}

type PojoDictionary<T extends string | symbol | number, U> = {
    [K in T]: U;
};

export function AuthController(props: AuthControllerProps): JSX.Element {
    // Destructure props
    const user = props.user;
    const setUser = props.setUser;
    const authState = props.authState;
    const setAuthState = props.setAuthState;

    const screens: PojoDictionary<AuthState, JSX.Element> = {
        SIGNIN: <SignInContainer setAuthState={setAuthState} setUser={setUser}/>,
        PASSWORD_FORGOT: <PasswordContainer user={user} setUser={setUser} authState={authState} setAuthState={setAuthState}/>,
        PASSWORD_RESET: <PasswordContainer user={user} setUser={setUser} authState={authState} setAuthState={setAuthState}/>,
        PASSWORD_NEW: <PasswordContainer user={user} setUser={setUser} authState={authState} setAuthState={setAuthState}/>,
        MFA_SMS: <MFAContainer user={user} setUser={setUser} authState={authState} setAuthState={setAuthState}/>,
        MFA_TOTP: <MFAContainer user={user} setUser={setUser} authState={authState} setAuthState={setAuthState}/>,
        MFA_TOTP_ENABLE: <MFAContainer user={user} setUser={setUser} authState={authState} setAuthState={setAuthState}/>,
        MFA_SELECT_TYPE: <MFAContainer user={user} setUser={setUser} authState={authState} setAuthState={setAuthState}/>,
        SIGNED_IN: <Fragment/>,
        ERROR: <ToLoginError setAuthState={setAuthState}/>,
    }

    useEffect(() => {
        if (user !== undefined) {
            const challengeName: string = (user.challengeName !== undefined) ? user.challengeName : 'NONE';
            setAuthState(ChallengeNameState[challengeName] || ChallengeNameState.ERROR);
        }
    }, [user, setAuthState]);

    return (
        <div style={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#fff',
        }}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                padding={'32px'}
            >
                {screens[authState]}
            </Grid>
        </div>
    )
}
