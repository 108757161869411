import { Fragment, MouseEvent } from 'react';

import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { useTranslation } from 'react-i18next';

import { Column, getColumns, Fields, Order } from '../tables/columns';

interface CustomTableProps {
    fields: Fields[],
    order: Order,
    orderBy: Fields,
    setOrder: Function,
    setOrderBy: Function,
}

/**
 * Render function that supplies the table header
 * @param {Fields[]}        CustomTableProps.fields         Which fields of the data should be rendered
 * @param {Order}           CustomTableProps.order          In what direction the data should be sorted
 * @param {Fields}          CustomTableProps.orderBy        Which field the data should be sorted by
 * @param {Function}        CustomTableProps.setOrder       State setter for order
 * @param {Function}        CustomTableProps.setOrderBy     State setter for orderBy
 * @returns {JSX.Element}                                   The resulting React Element
 */
export function TableHeader(props: CustomTableProps): JSX.Element {
    // Destructure props
    const fields = props.fields;
    const order = props.order;
    const orderBy = props.orderBy;
    const setOrder = props.setOrder;
    const setOrderBy = props.setOrderBy;

    // Set constants
    const { t } = useTranslation();
    const columns = getColumns(fields);

    // Set UI handlers
    function createSortHandler(property: any) { 
        return (_event: MouseEvent<unknown>) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }
    };

    /**
     * Iterable method that provides table cell elements
     * @param {Column} column   Which column header should be rendered
     * @returns {JSX.Element}   The resulting React Element TableCell
     */
    function prepareCell(column: Column): JSX.Element {
        let content: JSX.Element;
        if (column.disableSort) {
            content = 
                <Fragment>
                    {t(column.label)}
                </Fragment>
        } else {
            content = 
                <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={createSortHandler(column.id)}
                >
                    {t(column.label)}
                </TableSortLabel>
        }

        return(
            <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
                sortDirection={orderBy === column.id ? order : false}
            >
                { content }
            </TableCell>
        );
    }

    return (
        <TableHead>
            <TableRow>
                {columns.map(prepareCell)}
            </TableRow>
        </TableHead>
    );
}
