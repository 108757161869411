import { ChangeEvent, Dispatch, Fragment, SetStateAction, useState } from "react";
import { AmplifyUser } from "@aws-amplify/ui";
import { Auth } from "aws-amplify";

import { FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AlertError, parseErrorMessage } from "./errors";
import { GridActionButton, GridChangeStateButton, GridPasswordField, GridTextField, GridTitle } from "./inputs";
import { AuthState, ChallengeNameState, testPassword } from "../../../utility";


interface InputState {
    email: string,
    code: string,
    password: string,
    passwordVerify: string,
    error: string,
}

const emptyInput: InputState = {
    email: '',
    code: '',
    password: '',
    passwordVerify: '',
    error: '',
};

interface PasswordContainerProps {
    authState: AuthState,
    user: AmplifyUser | undefined,
    setAuthState: Dispatch<SetStateAction<AuthState>>,
    setUser: Dispatch<SetStateAction<AmplifyUser | undefined>>,
}

/**
 * Render function that supplies the content for the password pages
 * @param {AuthState}       PasswordContainerProps.authState
 *                          The state authentication currently is in
 * @param {AmplifyUser?}    PasswordContainerProps.user
 *                          The logged in AWS Amplify user
 * @param {Function}        PasswordContainerProps.setAuthState
 *                          State setter for authState
 * @param {Function}        PasswordContainerProps.setUser
 *                          State setter for user
 * @returns {JSX.Element}   The resulting React Element
 */
export function PasswordContainer(props: PasswordContainerProps): JSX.Element {
    // Destructure props
    const user = props.user;
    const setUser = props.setUser;
    const authState = props.authState;
    const setAuthState = props.setAuthState;
    
    // Initialise states
    const [input, setInput] = useState<InputState>(emptyInput);

    // Set constants
    const { t } = useTranslation();

    // Set UI handlers
    function handleTextfield(prop: keyof InputState) {
        return (event: ChangeEvent<HTMLInputElement>) => {
            setInput({ ...input, [prop]: event.target.value });
        };
    }

    // Set API methods
    function handleNewPassword(): void {
        (async () => {
            try {
                // Because the user itself remains the same 'object instance' pulled from the local cache it doesn't trigger useEffect
                // As such the state needs to be updated from here.
                const response: AmplifyUser | undefined = await Auth.completeNewPassword(user, input.password);
                const challengeName: string = (response?.challengeName !== undefined) ? response.challengeName : 'NONE';
                setUser(response);
                setAuthState(ChallengeNameState[challengeName] || ChallengeNameState.ERROR);
                setInput({...input, error: ''})
            } catch (error) {
                parseErrorMessage(error as Error, input, setInput);
            }
        })();
    }

    function handleResetPassword(): void {
        (async () => {
            try {
                await Auth.forgotPasswordSubmit(input.email, input.code, input.password);
                setAuthState('SIGNIN');
                setInput({...input, error: ''})
            } catch (error) {
                parseErrorMessage(error as Error, input, setInput);
            }
        })();
    }

    function handleRequestCode(): void {
        (async () => {
            try {
                await Auth.forgotPassword(input.email);
                setAuthState('PASSWORD_RESET');
                setInput({...input, error: ''})
            } catch (error) {
                parseErrorMessage(error as Error, input, setInput);
            }
        })();
    }

    // Set render methods
    function renderHelperInputPassword(): JSX.Element {
        if (testPassword(input.password).length === 0) {
            return (<Fragment/>);
        }

        return (
            <FormHelperText id="component-error-verify-text">
                <>{t('auth.password.header')}<br/></>
                { testPassword(input.password).map((item) => <> - { t(item) } <br/> </> )}
            </FormHelperText>
        );
    }

    function renderHelperInputVerifyPassword(): JSX.Element {
        if (input.password === input.passwordVerify) {
            return (<Fragment/>);
        }

        return (
            <FormHelperText id="component-error-verify-text">
                { t('auth.password.verify') }
            </FormHelperText>
        );
    }

    function renderError(): JSX.Element {
        if (input.error === '') {
            return (<Fragment/>);
        }

        return (
            <AlertError labelId={input.error}/>
        );
    }

    function renderSetNewPassword(): JSX.Element {
        return (
            <Fragment>
                <GridTitle
                    titleId='auth.setNewPassword'
                />
                <GridPasswordField
                    labelId='auth.field.newPassword'
                    password={input.password}
                    error={testPassword(input.password).length !== 0}
                    onChange={handleTextfield('password')}
                    formHelperText={renderHelperInputPassword()}
                />
                <GridPasswordField
                    labelId='auth.field.verifyPassword'
                    password={input.passwordVerify}
                    error={input.password !== input.passwordVerify}
                    onChange={handleTextfield('passwordVerify')}
                    formHelperText={renderHelperInputVerifyPassword()}
                />
                { renderError() }
                <GridChangeStateButton
                    labelId='auth.action.toSignin'
                    toAuthstate='SIGNIN'
                    setAuthState={setAuthState}
                />
                <GridActionButton
                    labelId='auth.action.setPassword'
                    disabled={
                        testPassword(input.password).length !== 0 
                        || input.password !== input.passwordVerify}
                    onClick={handleNewPassword}
                />
            </Fragment>
        );
    }

    function renderRequestCode(): JSX.Element {
        return (
            <Fragment>
                <GridTitle
                    titleId='auth.resetPassword'
                />
                <GridTextField
                    labelId='auth.field.email'
                    value={input.email}
                    onChange={handleTextfield('email')}
                />
                { renderError() }
                <GridChangeStateButton
                    labelId='auth.action.toSignin'
                    toAuthstate='SIGNIN'
                    setAuthState={setAuthState}
                />
                <GridActionButton
                    labelId='auth.action.requestCode'
                    disabled={input.email === ''}
                    onClick={handleRequestCode}
                />
            </Fragment>
        );
    }

    function renderResetPassword(): JSX.Element {
        return (
            <Fragment>
                <GridTitle
                    titleId='auth.resetPassword'
                />
                <GridTextField
                    labelId='auth.field.code'
                    value={input.code}
                    onChange={handleTextfield('code')}
                />
                <GridPasswordField
                    labelId='auth.field.newPassword'
                    password={input.password}
                    error={testPassword(input.password).length !== 0}
                    onChange={handleTextfield('password')}
                    formHelperText={renderHelperInputPassword()}
                />
                <GridPasswordField
                    labelId='auth.field.verifyPassword'
                    password={input.passwordVerify}
                    error={input.password !== input.passwordVerify}
                    onChange={handleTextfield('passwordVerify')}
                    formHelperText={renderHelperInputVerifyPassword()}
                />
                { renderError() }
                <GridActionButton
                    labelId='auth.action.requestCodeAgain'
                    variant='text'
                    onClick={handleRequestCode}
                />
                <GridActionButton
                    labelId='auth.action.setPassword'
                    disabled={
                        input.code === ''
                        || testPassword(input.password).length !== 0 
                        || input.password !== input.passwordVerify
                    }
                    onClick={handleResetPassword}
                />
            </Fragment>
        );
    }

    switch (authState) {
        case 'PASSWORD_NEW':
            return renderSetNewPassword();
        case 'PASSWORD_FORGOT':
            return renderRequestCode();
        case 'PASSWORD_RESET':
            return renderResetPassword();
        default:
            setAuthState('ERROR');
            return ( <Fragment/> )
    }
    
}