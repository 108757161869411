import { Response } from '../interfaces';
import { makeRequest } from '../makeRequest';
import { HTTP_ENDPOINTS, HTTP_METHODS, RESPONSE_FIELDS } from '../../utility/constants';
import { Credit } from "./credits";


export interface OrganisationCredit {
    valueCurrent: number,
}

interface CreateParams {
    organisationId: number,
    valueOriginal: number,
    doesExpire: boolean,
    expireDate?: string,
}

interface DeleteParams {
    id: number,
}

export async function organisationCreditRead(): Promise<Response<OrganisationCredit[]>> {
    const url = HTTP_ENDPOINTS.ORGANISATION_CREDITS;
    return makeRequest(url, {}, HTTP_METHODS.GET, RESPONSE_FIELDS.CREDITS);
}

export async function organisationCreditCreate({
    organisationId,
    valueOriginal,
    doesExpire = false,
    expireDate = undefined,
}: CreateParams): Promise<Response<OrganisationCredit[]>> {
    const url = HTTP_ENDPOINTS.ORGANISATION_CREDITS;
    const data = {
        organisationId: organisationId,
        valueOriginal: valueOriginal,
        doesExpire: doesExpire,
        expireDate: expireDate,
    };
    return makeRequest(url, data, HTTP_METHODS.POST, RESPONSE_FIELDS.CREDITS);
}

export async function organisationCreditDelete({
    id,
}: DeleteParams): Promise<Response<Credit[]>> {
    const url = `${HTTP_ENDPOINTS.ORGANISATION_CREDITS}/${id}`;
    
    return makeRequest(url, {}, HTTP_METHODS.DELETE, RESPONSE_FIELDS.CREDITS);
}
