import { MessageComponent } from "../records/message";

/**
 * Type to specify which field names are allowed to be used for columns
 */
export type Fields = 
    | 'id'
    | 'name'
    | 'email'
    | 'enforceMFA'
    | 'uploadDate'
    | 'visibleToAdmin'
    | 'organisationName'
    | 'phone'
    | 'userName'
    | 'message'
    | 'fileExtension'
    | 'credits'
    | 'cost'
    | 'costName'
    | 'expireDate'
    | 'valueCurrent'
    | 'valueOriginal'
;

/**
 * Type to specify which direction a field may be sorted in
 */
export type Order = 'asc' | 'desc';

/**
 * The data a column requires to be rendered
 */
export interface Column {
    id: Fields;
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: any) => string | JSX.Element;
    disableSort?: boolean;
    maxWidth?: number;
    overflow?: string;
    whiteSpace?: 'normal' | 'nowrap';
    textOverflow?: string;
}

function formatNumber(value: number | string): string {
    if (typeof value === 'string') {
        return value;
    }
    return value.toLocaleString('en-US');
}

function formatId(value: number): string {
    if (value < 0) {
        return '';
    }

    return formatNumber(value);
}

function formatBoolean(value: boolean): string {
    switch (value) {
        case true: return 'True';
        case false: return 'False';
    }
}

function formatDate(value: string): string {
    if (value === '' || value === undefined || value === null) {
        return '';
    }

    const date = new Date(Date.parse(value));
    return date.toLocaleString("en-GB");
}

function formatMessage(value: number | string): JSX.Element {
    return (
        <MessageComponent value={value}/>
    );
}

/**
 * Internal settings that specifies how all columns are rendered
 */
const staticColumns: Record<Fields, Column> = {
    id: {
        id: 'id',
        label: 'table.columns.id',
        minWidth: 20,
        align: 'right',
        format: formatId,
        disableSort: true,
    },
    name: {
        id: 'name',
        label: 'table.columns.name',
        minWidth: 80,
        maxWidth: 200,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    email: {
        id: 'email',
        label: 'table.columns.email',
        minWidth: 150,
        disableSort: true,
    },
    enforceMFA: {
        id: 'enforceMFA',
        label: 'table.columns.enforceMFA',
        minWidth: 85,
        align: 'right',
        format: formatBoolean,
    },
    uploadDate: {
        id: 'uploadDate',
        label: 'table.columns.uploadDate',
        minWidth: 100,
        format: formatDate,
    },
    expireDate: {
        id: 'expireDate',
        label: 'table.columns.expireDate',
        minWidth: 100,
        format: formatDate,
    },
    visibleToAdmin: {
        id: 'visibleToAdmin',
        label: 'table.columns.visibleToAdmin',
        minWidth: 40,
        align: 'right',
        format: formatBoolean,
    },
    organisationName: {
        id: 'organisationName',
        label: 'table.columns.organisationName',
        minWidth: 40,
        align: 'right',
    },
    phone: {
        id: 'phone',
        label: 'table.columns.phone',
        minWidth: 40,
        align: 'right',
        disableSort: true,
    },
    userName: {
        id: 'userName',
        label: 'table.columns.userName',
        minWidth: 40,
        align: 'right',
    },
    message: {
        id: 'message',
        label: 'table.columns.fakeChance',
        minWidth: 40,
        align: 'right',
        format: formatMessage,
    },
    fileExtension: {
        id: 'fileExtension',
        label: 'table.columns.fileExtension',
        minWidth: 40,
    },
    credits: {
        id: 'credits',
        label: 'table.columns.credits',
        minWidth: 72,
        disableSort: true,
    },
    valueCurrent: {
        id: 'valueCurrent',
        label: 'table.columns.valueCurrent',
        minWidth: 80,
    },
    cost: {
        id: 'cost',
        label: 'table.columns.cost',
        minWidth: 80,
    },
    costName: {
        id: 'name',
        label: 'table.columns.costName',
        minWidth: 80,
    },
    valueOriginal: {
        id: 'valueOriginal',
        label: 'table.columns.valueOriginal',
        minWidth: 80,
    },
}

/**
 * Getter function for the column settings
 * @param {Fields[]} fields Which fields to get the render settings for
 * @returns {Column[]} Array containing all requested settings
 */
export function getColumns(fields: Fields[]): Column[] {
    const columns: Column[] = [];

    for (const field of fields) {
        columns.push(staticColumns[field])
    }

    return columns;
}
