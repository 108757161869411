import { ChangeEvent, Fragment, SyntheticEvent, useEffect, useRef, useState } from 'react';

import { Alert, AlertColor, Button, Dialog, DialogActions, DialogTitle, Grid, Stack, Tooltip, IconButton, FormControl } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress'; 
import { HelpOutlineOutlined, ThumbUpOffAlt, ThumbDownOffAlt, ThumbUp, ThumbDown  } from '@mui/icons-material';
import ReplayIcon from '@mui/icons-material/Replay';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { GridBackButton, GridCheckBox, GridSlider, GridTextField, GridTitle, TableTitleGrid } from './inputs';
import { Table, Fields, Order } from '../tables';
import { DragDropFile } from '../records';
import { FilesComponent } from '../records/storage';
import { Record, recordsCreate, recordsDelete, recordsRead, recordsUpdate, Permissions, organisationsRead, Organisation } from "../../../api/endpoints";
import { costsRead, Cost as CostInterface } from '../../../api/endpoints/costs';
import { orderMapping } from '../../../api/orderMapping';
import { analysisResultParser, getImageExtensions, getVideoExtensions, requestResultParser } from "../../../utility";
import { MESSAGE_OK, PATHS } from "../../../utility/constants";
import { Settings } from '../../../settings';
import { RequestAlert } from './messages';
import { getAudioExtensions } from "../../../utility/fileExtensions";

interface RecordContainerProps {
    permissions: Permissions,
    isAdd?: boolean,
    settings: Settings,
    credits: number,
    refreshCredits: () => Promise<void>,
}

export interface InputState {
    visibleToAdmin: boolean,
    generateXAI: boolean,
    name: string,
    fileName: string,
    fileExtension: string,
    fileURL: string,
    urls: string[],
    fileDuration: number,
    analysisDuration: number[],
    error: string,
    approved?: number
}

const emptyInput: InputState = {
    visibleToAdmin: true,
    generateXAI: false,
    name: '',
    fileName:'',
    fileExtension: '',
    fileURL: '',
    urls: [],
    fileDuration: 0,
    analysisDuration: [0, 0],
    error: '',
};

interface Cost {
    'image': number,
    'image-xai': number,
    'video-second': number,
    'video-second-xai': number,
    'audio-second': number,
    'audio-second-xai': number,
}

const emptyCosts = {
    'image': 0,
    'image-xai': 0,
    'video-second': 0,
    'video-second-xai': 0,
    'audio-second': 0,
    'audio-second-xai': 0,
}

/**
 * Render function that supplies the content for the record page
 * @param {Permissions}     RecordContainerProps.permissions
 *                          The user's permission data
 * @param {boolean}         RecordContainerProps.isAdd
 *                          Whether the content should contain UI to add an entry to the database
 * @param {Settings}        OrganisationContainerProps.settings
 *                          The web app global settings
 * @param {number}          OrganisationContainerProps.credits
 *                          The user's available credits
 * @returns {JSX.Element}   The resulting React Element
 */
export function RecordContainer(props: RecordContainerProps): JSX.Element {
    // Destructure props
    const permissions = props.permissions;
    const isAdd = (props.isAdd);
    const settings = props.settings;
    const credits = props.credits;
    const refreshCredits = props.refreshCredits;

    const _selected = Number(useParams().id);
    const selected = (!Number.isNaN(_selected)) ? _selected : 0; // 0 if nothing selected

    // Initialise states
    const [records, setRecords] = useState<Record[]>([]);
    const [count, setCount] = useState<number>(0);
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<Fields>('id');
    const [page, setPage] = useState<number>(1);
    const [input, setInput] = useState<InputState>(emptyInput);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [rowsPerPage, setRowsPerPage] = useState<number>(settings.rowsPerPage);
    const [fileName, setFileName] = useState<string>('');
    const [org, setOrg] = useState<string>('');
    const organisations = useRef<Organisation[]>([]);

    const [costs, setCosts] = useState<Cost>(emptyCosts);
    const [message, setMessage] = useState<string>('');

    useEffect(() => {
        (async () => {
            setInput(emptyInput);
            await readRecord();
            setLoading(false); // Refresh button stops spinning
            if (!selected) {
                return;
            }
            
            await setInputSync();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, orderBy, page, selected, isAdd, loading, rowsPerPage, fileName, org]);

    useEffect(() => {
        (async () => {
            await readCost()
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if(permissions.role === "admin"){
                organisations.current = await readOrganisation()
            }

        })();
    }, [permissions])

    // go back to the first page when the rows per page changes
    useEffect(() => {
        setLoading(true);
        setPage(1);
    }, [rowsPerPage]);

    function setAnalysisDuration(range: number[]) {
        setInput({
            ...input,
            analysisDuration: range,
        })
    }

    async function readOrganisation() {
        const readResponse = await organisationsRead({});
        const responseItems = readResponse.response ?? [];
        const parsedResponse: Organisation[] = [];
        for (const item of responseItems) {
            parsedResponse.push({...item, hasEdit: true, hasDelete: true});
        }

        if (readResponse.errorMessage !== undefined && readResponse.errorMessage !== '') {
            setMessage(readResponse.errorMessage);
        }
        return parsedResponse;
    }

    // Set constants
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set sync handlers
    async function setInputSync() {
        const response = await recordsRead({id: selected});

        let message = '';
        if (response.errorMessage !== undefined && response.errorMessage !== '') {
            message = response.errorMessage;
        }
        setMessage(message);
        if (!response.response) {
            return;
        }

        setInput({
            ...(response.response[0]),
            fileURL: '',
            generateXAI: input.generateXAI,
            fileDuration: 0,
            analysisDuration: [0, 0],
        });
    }

    // Set UI handlers
    function handleTextfield(prop: keyof InputState) {
        return (event: ChangeEvent<HTMLInputElement>) => {
            setInput({ ...input, [prop]: event.target.value });
        };
    }

    function handleCheckbox(prop: keyof InputState) {
        return (event: SyntheticEvent<Element, Event>, checked: boolean) => {
            setInput({ ...input, [prop]: checked });
        };
    }

    function handleReload() {
        if (!loading) {
            setLoading(true);
        }
    }

    function handleFileName(event: ChangeEvent<HTMLInputElement>) {
        setPage(1);
        setFileName(event.target.value);
    }

    function handleOrganizationChange(event: SelectChangeEvent){
        setOrg(event.target.value)
    }

    async function handleApproved(approved: boolean){
        // calculate the new approved value
        let newApproved = 0;
        if(approved && input.approved!==1){
            newApproved = 1
        } else if(!approved && input.approved!==-1){
            newApproved = -1
        }

        // update the input state
        setInput({...input, approved: newApproved})
        const updateResponse = await recordsUpdate({id: selected, values: {'approved': newApproved}}); // Use the new state of the input
        
        // display an error message if there is one, skip otherwise
        let message: string = MESSAGE_OK.RECORD_UPDATE;
        if (updateResponse.errorMessage !== undefined && updateResponse.errorMessage !== '') {
            message = updateResponse.errorMessage;
            setMessage(message);
        }
    }

    /** Calculate the appropriate cost for the current state of the request */
    function calculateCost(): number {
        if (getVideoExtensions().includes(input.fileExtension)) {
            if (input.generateXAI) {
                return costs['video-second-xai'] * (input.analysisDuration[1] - input.analysisDuration[0]);
            }
            return costs['video-second'] * (input.analysisDuration[1] - input.analysisDuration[0]);
        }

        if (getImageExtensions().includes(input.fileExtension)) {
            if (input.generateXAI) {
                return costs['image-xai'];
            }
            return costs['image'];
        }

        if (getAudioExtensions().includes(input.fileExtension)) {
            if (input.generateXAI) {
                return costs['audio-second-xai'] * (input.analysisDuration[1] - input.analysisDuration[0]);
            }
            return costs['audio-second'] * (input.analysisDuration[1] - input.analysisDuration[0]);
        }

        return 0;
    }

    // Set API methods
    async function addRecord() {
        const createResponse = await recordsCreate({
            ...input,
            userId: permissions.userId,
            organisationId: permissions.organisationId,
            isVideo: getVideoExtensions().includes(input.fileExtension),
            createExplainable: input.generateXAI,
            startSecond: input.analysisDuration[0],
            endSecond: input.analysisDuration[1],
        });
        setInput(emptyInput);
        await readRecord();
        await refreshCredits();

        let message: string = MESSAGE_OK.RECORD_CREATE;
        if (createResponse.errorMessage !== undefined && createResponse.errorMessage !== '') {
            message = createResponse.errorMessage;
        }
        setMessage(message);
        navigate(PATHS.RECORDS);
    }

    async function readRecord() {
        const readResponse =  await recordsRead({
            orderFields: [`${orderMapping(orderBy, 'records', order) }`],
            limit: rowsPerPage,
            offset: (page - 1) * rowsPerPage,
            search: {
                name: fileName,
                organisationName: org
            }
        });
        const responseItems = readResponse.response ?? [];
        const parsedResponse: Record[] = [];
        for (const item of responseItems) {
            const message = analysisResultParser({chance: item.fakeChance, error: item.error});
            parsedResponse.push({...item, message: message});
        }

        if (readResponse.errorMessage !== undefined && readResponse.errorMessage !== '') {
            setMessage(readResponse.errorMessage);
        }
        setRecords(parsedResponse || []);
        setCount(readResponse.count || 0);
    }

    async function editRecord() {
        const updateResponse = await recordsUpdate({id: selected, values: input});
        await readRecord();

        let message: string = MESSAGE_OK.RECORD_UPDATE;
        if (updateResponse.errorMessage !== undefined && updateResponse.errorMessage !== '') {
            message = updateResponse.errorMessage;
        }
        setMessage(message);
    }

    async function deleteRecord({id}: {id: number}) {
        const deleteResponse = await recordsDelete({id: id});
        await readRecord();

        let message: string = MESSAGE_OK.RECORD_DELETE;
        if (deleteResponse.errorMessage !== undefined && deleteResponse.errorMessage !== '') {
            message = deleteResponse.errorMessage;
        }
        setMessage(message);
        navigate(PATHS.RECORDS);
    }

    async function readCost() {
        const readResponse = await costsRead({});
        const costs: CostInterface[] = readResponse.response ?? [];
        const parsedCosts = emptyCosts;
        for (const cost of costs) {
            if (
                cost.name === 'image'
                || cost.name === 'image-xai'
                || cost.name === 'video-second'
                || cost.name === 'video-second-xai'
                || cost.name === 'audio-second'
                || cost.name === 'audio-second-xai'
            ){
                parsedCosts[cost.name] = cost.cost;
            }
        }
        setCosts(parsedCosts);
        
        if (readResponse.errorMessage !== undefined && readResponse.errorMessage !== '') {
            setMessage(readResponse.errorMessage);
        }
    }

    function disableGenerateXAI(){
        return getAudioExtensions().includes(input.fileExtension.toLowerCase())
    }

    // Render methods

    /**
     * Renders any possible messages that occur due to REST operations
     * @returns {JSX.Element} The resulting React Element
     */
    function renderMessage(): JSX.Element {
        return (
            <RequestAlert
                messageId={requestResultParser(message)}
                error={!(/OK/.test(message))}
                setMessage={setMessage}
            />
        );
    }

    /**
     * Renders the time selection slider if the uploaded file is a video
     * @returns {JSX.Element} The resulting React Element
     */
    function renderSlider(): JSX.Element {
        if (!getVideoExtensions().includes(input.fileExtension) && !getAudioExtensions().includes(input.fileExtension)) {
            return <Fragment/>
        }

        return (
            <GridSlider
                maxDuration={input.fileDuration}
                analysisDuration={input.analysisDuration}
                setAnalysisDuration={setAnalysisDuration}
            />
        );
    }

    /**
     * Renders how much credits the request will cost
     * @returns {JSX.Element} The resulting React Element
     */
    function renderCost(): JSX.Element {
        function renderAlert(content: string, severity: AlertColor) {
            return (
                <Alert severity={severity} sx={{ py: '0px', fontSize: "14px" }}>
                    {content}
                </Alert> 
            );
        }

        if (costs['image'] === undefined) {
            return renderAlert(t('record.cost.notFound'), 'error');
        }

        if (input.fileName === '') {
            return (<Fragment/>);
        }

        const cost = calculateCost();
        if (cost <= credits) {
            return renderAlert(`${t('record.cost.willCost')} ${cost} ${t('record.cost.credits')}.`, 'success');
        }

        return renderAlert(`${t('record.cost.willCost')} ${cost} ${t('record.cost.credits')}. ${t('record.cost.noCredits')}`, 'error');
    }

    /**
     * Renders the UI for adding an entry to the database
     * @returns {JSX.Element} The resulting React Element
     */
    function renderAddContent(): JSX.Element {
        if (!isAdd) {
            return (<Fragment/>);
        }

        return (
            <Grid container spacing={2} id="add-record" sx={{marginBottom: 2}}>
                <GridTitle titleId='record.add'/>
                <GridBackButton path={`${PATHS.RECORDS}`}/>
                <DragDropFile
                    permissions={permissions}
                    input={input}
                    setInput={setInput}
                />
                <GridTextField
                    labelId='record.field.name'
                    value={input.name}
                    onChange={handleTextfield('name')}
                />
                <GridCheckBox
                    labelId='record.field.generateXAI'
                    value={input.generateXAI}
                    disabled={disableGenerateXAI()}
                    hidden={disableGenerateXAI()}
                    onChange={handleCheckbox('generateXAI')}
                />
                <GridCheckBox
                    labelId='record.field.visibleToAdmin'
                    tooltipIcon={
                        <Tooltip title={t('record.field.visibleToAdminTooltip')}>
                            <HelpOutlineOutlined sx={{color: '#01b3be'}}/>
                        </Tooltip>
                    }
                    value={input.visibleToAdmin}
                    onChange={handleCheckbox('visibleToAdmin')}
                />
                
                
                {renderSlider()}
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="end" spacing={2}>
                        {renderCost()}
                        <Button
                            onClick={addRecord}
                            variant='contained'
                            sx={{ fontSize: 14 }}
                            disabled={input.name === '' || input.fileName === '' || calculateCost() > credits}
                        >
                            {!(input.name === '' || input.fileName === '' || calculateCost() > credits) ? t('record.confirm') : t('record.add')}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        );
    }
    
    /**
     * Renders the UI for altering an entry in the database
     * @returns {JSX.Element} The resulting React Element
     */
    function renderEditContent(): JSX.Element {
        if (selected === 0) {
            return (<Fragment/>);
        }

        return (
            <Grid container spacing={2} id="edit-record" sx={{marginBottom: 2}}>
                <GridTitle titleId='record.edit'/>
                <GridTextField
                    labelId='record.field.name'
                    value={input.name}
                    onChange={handleTextfield('name')}
                />
                <GridCheckBox
                    labelId='record.field.visibleToAdmin'
                    value={input.visibleToAdmin}
                    onChange={handleCheckbox('visibleToAdmin')}
                />
                <Grid item xs={12} lg={12}>
                    <Stack spacing={2} direction="row" justifyContent="end" alignItems="center">
                        {input.name!=='' ? 
                        <>
                        <Typography sx={{color: "#707070"}} variant="body2">
                            {t('record.feedback.question')}
                        </Typography>
                        <Tooltip title={t('record.feedback.tooltip')}>
                        <IconButton aria-label="thumbsUp" size="small" sx={{margin: "0!important"}} onClick = {(e) => handleApproved(true)}>
                            {input.approved===1 ? 
                                <ThumbUp fontSize="inherit" />:
                                <ThumbUpOffAlt fontSize="inherit" />
                            }
                        </IconButton>
                        </Tooltip>
                        <Tooltip title={t('record.feedback.tooltip')}>
                        <IconButton aria-label="thumbsDown" size="small" sx={{margin: "0!important"}} onClick = {(e) => handleApproved(false)}>
                            {input.approved===-1 ? 
                                <ThumbDown fontSize="inherit" />:
                                <ThumbDownOffAlt fontSize="inherit" />
                            }
                        </IconButton>
                        </Tooltip>
                        </>:null}
                        <Button
                            onClick={editRecord}
                            variant='contained'
                            sx={{ fontSize: 14 }}
                            disabled={input.name === '' || input.fileName === ''}
                        >
                            {t('record.edit')}
                        </Button>
                        <Button
                            color="error"
                            onClick={() => setDialogOpen(true)}
                            variant='contained'
                            sx={{ fontSize: 14 }}
                        >
                            {t('record.delete')}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        );
    }
    
    /**
     * Renders the deletion dialog
     * @returns {JSX.Element}           The resulting React Element
     */
    function renderDialog(): JSX.Element {
        return (
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            >
                <DialogTitle>
                    {`${t('record.dialog.delete')} ${input.name}?`}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>
                        {t('dialog.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            setDialogOpen(false)
                            deleteRecord({id: selected});
                        }}
                        autoFocus>
                        {t('dialog.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
    
    /**
     * Renders the UI for downloading all relevant files
     * @returns {JSX.Element} The resulting React Element
     */
    function renderDownloadContent(): JSX.Element {
        if (selected === 0) {
            return (<Fragment/>);
        }

        if (!input.fileName) {
            return <Fragment/>;
        }

        return (
            <Grid
                container
                spacing={2}
                id="edit-record"
                sx={{marginBottom: 2}}
                alignItems="flex-end"
            >
                <GridTitle titleId='record.download'/>
                <GridBackButton path={`${PATHS.RECORDS}`}/>
                <FilesComponent urls={input.urls}/>
            </Grid>
        );
    }

    /**
     * Renders the title shown above the table
     * @returns {JSX.Element}       The resulting React Element
     */
    function renderTableTitle(): JSX.Element {
        const buttonVisible = 
            !isAdd 
            && !selected
        ;

        const buttonDisabled = 
            credits <= 0
            || permissions.deactivated
        ;

        return (
            <TableTitleGrid
                titleId={'record.title'}
                labelId={'record.add'}
                buttonVisible={buttonVisible}
                buttonDisabled={buttonDisabled}
                path={`${PATHS.RECORDS}${PATHS.ADD}`}
            />
        );
    }

    /**
     * Renders the table itself
     * @returns {JSX.Element} The resulting React Element
     */
    function renderTable(): JSX.Element {
        let fields: Fields[] = ['id', 'name', 'fileExtension', 'uploadDate', 'message', 'visibleToAdmin'];
        if (permissions.role === 'client') {
            fields = ['id', 'userName', 'name', 'fileExtension', 'uploadDate', 'message', 'visibleToAdmin'];
        }
        if (permissions.role === 'admin') {
            fields = ['id', 'organisationName', 'userName', 'name', 'fileExtension', 'uploadDate', 'message', 'visibleToAdmin'];
        }
        return (
            <>
                <Stack direction="row" justifyContent="end" spacing={2} marginBottom="4px">
                    {permissions.role === 'admin' ? (
                    <FormControl sx={{minWidth: 200}} size="small">
                        <InputLabel>Organization</InputLabel>
                        <Select
                            value={org}
                            label="Organization"
                            onChange={handleOrganizationChange}
                        >
                            <MenuItem value="">
                            <Typography variant="body2" gutterBottom>
                               All
                            </Typography>
                            </MenuItem>
                            {
                                organisations.current.map(org => (
                                    <MenuItem key={org.id} value={org.name}>
                                        <Typography variant="body2" gutterBottom>
                                        {org.name}
                                        </Typography>
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    ): null}

                    <TextField 
                        label="Filename" 
                        value={fileName}
                        onChange={handleFileName} 
                        variant="outlined"
                        InputProps={
                            {
                                endAdornment: 
                                    (
                                    <IconButton
                                        aria-label="search"
                                        edge="end"
                                        onClick={() => setFileName('')}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )
                            }
                        }
                    />
                    <IconButton aria-label="replay" onClick={handleReload} id="reload-button">
                        {loading && <CircularProgress size={24} color="inherit" />}
                        {!loading && <ReplayIcon  />}
                    </IconButton>
                </Stack>
                <Table
                    fields={fields}
                    rows={records}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    count={count}
                    order={order}
                    orderBy={orderBy}
                    page={page}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    setPage={setPage}
                    endpoint={PATHS.RECORDS}
                    loading={loading}
                    setLoading={setLoading}
                />
            </>
        );
    }

    return (
        <Fragment>
            {renderMessage()}
            {renderAddContent()} 
            {renderDownloadContent()} 
            {renderEditContent()} 
            {renderTableTitle()}
            {renderTable()}
            {renderDialog()}
        </Fragment>
    );
}
