import { ChangeEvent, Fragment, useEffect, useState } from "react";

import { Button, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { GridBackButton, GridTextField, GridTitle, TableTitleGrid } from "./inputs";
import { Fields, Order, Table } from "../tables";
import { Cost, costsRead, costsUpdate } from "../../../api/endpoints";
import { orderMapping } from "../../../api/orderMapping";
import { MESSAGE_OK, PATHS } from "../../../utility/constants";
import { Settings } from '../../../settings';
import { RequestAlert } from "./messages";
import { requestResultParser } from "../../../utility";

interface CostContainerProps {
    settings: Settings,
}

interface InputState {
    name: string,
    cost: number,
}

const emptyInput: InputState = {
    name: '',
    cost: 0,
}

/**
 * Render function that supplies the content for the cost page
 * @param {Settings}        OrganisationContainerProps.settings
 *                          The web app global settings
 * @returns {JSX.Element}   The resulting React Element
 */
export function CostContainer(props: CostContainerProps): JSX.Element {
    // Destructure props
    const settings = props.settings;
    
    const _selected = Number(useParams().id);
    const selected = (!Number.isNaN(_selected)) ? _selected : 0; // 0 if nothing selected

    // Initialise states
    const [costs, setCosts] = useState<Cost[]>([]);;
    const [count, setCount] = useState<number>(0);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<Fields>('id');
    const [page, setPage] = useState<number>(1);
    const [input, setInput] = useState<InputState>(emptyInput);
    const [message, setMessage] = useState<string>('');

    useEffect(() => {
        (async () => {
            await readCost();

            if (!selected) { 
                return;
            }

            await setInputSync();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, orderBy, page, selected]);

    // Set constants
    const { t } = useTranslation();

    // Set UI handlers
    function handleTextfield(prop: keyof InputState) {
        return (event: ChangeEvent<HTMLInputElement>) => {
            let cost = parseInt(event.target.value);
            if (cost < 0) {
                cost = 0;
            }
            setInput({ ...input, [prop]: cost });
        };
    }

    // Set API methods

    async function readCost() {
        const readResponse = await costsRead({
            orderFields: [`${orderMapping(orderBy, 'costs', order) }`],
            limit: settings.rowsPerPage,
            offset: (page - 1) * settings.rowsPerPage,
        });

        let message: string = '';
        if (readResponse.errorMessage !== undefined && readResponse.errorMessage !== '') {
            message = readResponse.errorMessage;
        }
        setMessage(message);
        setCosts(readResponse.response || []);
        setCount(readResponse.count || 0);
    }

    async function editCost() {
        const updateResponse = await costsUpdate({id: selected, values: input});
        await readCost();

        let message: string = MESSAGE_OK.COST_UPDATE;
        if (updateResponse.errorMessage !== undefined && updateResponse.errorMessage !== '') {
            message = updateResponse.errorMessage;
        }
        setMessage(message);
    }

    // Set sync handlers
    async function setInputSync() {
        const costResponse = await costsRead({id: selected});
        if (costResponse.errorMessage !== undefined && costResponse.errorMessage !== '') {
            setMessage(costResponse.errorMessage);
        }
        if (!costResponse.response) {
            return;
        }

        const selectedItem = costResponse.response[0];

        setInput({name: selectedItem.name, cost: selectedItem.cost});
    }

    // Render methods

    /**
     * Renders any possible messages that occur due to REST operations
     * @returns {JSX.Element} The resulting React Element
     */
    function renderMessage(): JSX.Element {
        return (
            <RequestAlert
                messageId={requestResultParser(message)}
                error={!(/OK/.test(message))}
                setMessage={setMessage}
            />
        );
    }
    
    /**
     * Renders the UI for altering an entry in the database
     * @returns {JSX.Element} The resulting React Element
     */
    function renderEditContent(): JSX.Element {
        if (selected === 0) {
            return (<Fragment/>);
        }

        return (
            <Grid container spacing={2} id="edit-cost" sx={{marginBottom: 2}}>
                <GridTitle titleId='cost.edit'/>
                <GridBackButton path={`${PATHS.COSTS}`}/>
                <GridTextField
                    labelId='cost.field.name'
                    value={input.name}
                    onChange={handleTextfield('name')}
                    disabled
                />
                <GridTextField
                    labelId='cost.field.cost'
                    value={input.cost.toString()}
                    onChange={handleTextfield('cost')}
                    numeric
                />
                <Grid item xs={12} lg={2.3}>
                    <Stack direction="row" justifyContent="end">
                        <Button
                            onClick={editCost}
                            variant='contained'
                            sx={{ fontSize: 14 }}
                        >
                            {t('cost.edit')}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
       );
    }

    /**
     * Renders the title shown above the table
     * @returns {JSX.Element}           The resulting React Element
     */
    function renderTableTitle(): JSX.Element {
        return (
            <TableTitleGrid
                titleId={'cost.title'}
                labelId={''}
                buttonVisible={false}
                path={''}
            />
        );
    }

    /**
     * Renders the table itself
     * @returns {JSX.Element} The resulting React Element
     */
    function renderTable(): JSX.Element {
        return (
            <Table
                fields={['costName', 'cost']}
                rows={costs}
                rowsPerPage={settings.rowsPerPage}
                count={count}
                order={order}
                orderBy={orderBy}
                page={page}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setPage={setPage}
                endpoint={PATHS.COSTS}
            />
        );
    }

    return (
        <Fragment>
            {renderMessage()}
            {renderEditContent()}
            {renderTableTitle()}
            {renderTable()}
        </Fragment>
    );
}
