import { TableBody, TableCell, TableRow } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { Column, Fields, getColumns } from "./columns";
import { PathValues } from "../../../utility/constants";


interface CustomTableBodyProps {
    fields: Fields[],
    rows: any[],
    rowsPerPage: number,
    endpoint: PathValues,
}

/**
 * Render function that supplies the table body
 * @param {Fields[]}        CustomTableProps.fields         Which fields of the data should be rendered
 * @param {any[]}           CustomTableProps.rows           The data that should populate the table
 * @param {number}          CustomTableProps.rowsPerPage    The amount of rows shown
 * @param {PathValues}      CustomTableProps.endpoint       Which endpoint we are currently on
 * @param {Function}        CustomTableProps.deleteFunction Function that removes an entry from the database
 * @returns {JSX.Element}                                   The resulting React Element
 */
export function CustomTableBody(props: CustomTableBodyProps): JSX.Element {
    // Destructure props
    const fields = props.fields;
    const rows = props.rows;
    // const rowsPerPage = props.rowsPerPage;
    const endpoint = props.endpoint;
    
    // Set constants
    const navigate = useNavigate();
    const _selected = Number(useParams().id);
    const selected = (!Number.isNaN(_selected)) ? _selected : 0; // 0 if nothing selected
    const columns = getColumns(fields);

    // Fill possible empty rows
    // if (rows.length < rowsPerPage) {
    //     const toFill = rowsPerPage - rows.length;
    //     for (let i = 0; i < toFill; i++) {
    //         // Negative id's to prevent id overlap
    //         rows.push({id: (-1 - i)});
    //     }
    // }

    // Set UI handlers
    function handleNavigate(rowId: number): void {
        if (rowId < 0) {
            return;
        }

        navigate(`${endpoint}/${rowId}`);
    }

    // Render methods

    /**
     * Iterable method that provides table cell elements
     * @param {any}     row     The entry data
     * @param {Column}  column  Which column of the data should be rendered
     * @returns {JSX.Element}   The resulting React Element TableCell
     */
    function prepareCell(row: any, column: Column): JSX.Element {
        let value = row[column.id];
        if (column.format !== undefined) {
            value = column.format(value);
        }

        return (
            <TableCell key={column.id} align={column.align} 
            sx={{
                maxWidth: column.maxWidth, 
                overflow: column.overflow, 
                textOverflow: column.textOverflow,
                whiteSpace: column.whiteSpace,
                }}
            >
                { value }
            </TableCell>
        );
    }

    /**
     * Iterable method that provides table row elements
     * @param {any} row         The entry data
     * @returns {JSX.Element}   The resulting React Element TableCell
     */
    function prepareRow(row: any): JSX.Element {
        return (
            <TableRow
                selected={selected === row.id}
                hover={row.id > 0}
                role="checkbox"
                tabIndex={-1}
                key={row.id}
                onClick={() => handleNavigate(row.id)}
                sx={{
                    cursor: (row.id > 0) ? 'pointer' : 'default',
                }}
            >
                {columns.map((column) => prepareCell(row, column))}
            </TableRow>
        );
    }

    return (
        <TableBody>
            { rows.map(prepareRow) }
        </TableBody>
    );
}
