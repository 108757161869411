const fileExtensions = {
    image: [
        "jpg",
        "jpeg",
        "png",
    ],
    video: [
        "mp4",
    ],
    audio: [
        "mp3",
        "wav",
        "flac",
    ],
};

export function getImageExtensions(): string[] {
    return fileExtensions.image;
}

export function getVideoExtensions(): string[] {
    return fileExtensions.video;
}

export function getAudioExtensions(): string[] {
    return fileExtensions.audio;
}

export function getAllExtensions(): string[] {
    return fileExtensions.image.concat(fileExtensions.video, fileExtensions.audio);
}