import { ChangeEvent, Dispatch, Fragment, SetStateAction, useState } from "react";
import { AmplifyUser } from "@aws-amplify/ui";
import { Auth } from "aws-amplify";

import { ToLoginError, parseErrorMessage } from "./errors";
import { GridActionButton, GridTOTPSetupQR, GridTextField, GridTitle } from "./inputs";
import { AuthState } from "../../../utility";

interface InputState {
    code: string,
}

const emptyInput: InputState = {
    code: '',
};

interface MFAContainerProps {
    authState: AuthState,
    user: AmplifyUser | undefined,
    setUser: Dispatch<SetStateAction<AmplifyUser | undefined>>,
    setAuthState: Dispatch<SetStateAction<AuthState>>,
}

/**
 * Render function that supplies the content for the mfa pages
 * @param {AuthState}       PasswordContainerProps.authState
 *                          The state authentication currently is in
 * @param {AmplifyUser?}    PasswordContainerProps.user
 *                          The logged in AWS Amplify user
 * @param {Function}        PasswordContainerProps.setAuthState
 *                          State setter for authState
 * @param {Function}        PasswordContainerProps.setUser
 *                          State setter for user
 * @returns {JSX.Element}   The resulting React Element
 */
export function MFAContainer(props: MFAContainerProps): JSX.Element {
    // Destructure props
    const user = props.user;
    const setUser = props.setUser;
    const authState = props.authState;
    const setAuthState = props.setAuthState;
    
    // Initialise states
    const [input, setInput] = useState<InputState>(emptyInput);

    // Set UI handlers
    function handleTextfield(prop: keyof InputState) {
        return (event: ChangeEvent<HTMLInputElement>) => {
            setInput({ ...input, [prop]: event.target.value });
        };
    }

    // Set API methods
    function handleSubmit(): void {
        (async () => {
            try {
                if (authState === 'MFA_TOTP_ENABLE') {
                    await Auth.verifyTotpToken(
                        user,
                        input.code,
                    );
                }

                if (authState === 'MFA_SMS') {
                    await Auth.confirmSignIn(
                        user,
                        input.code,
                        "SMS_MFA",
                    );
                }

                if (authState === 'MFA_TOTP') {
                    await Auth.confirmSignIn(
                        user,
                        input.code,
                        "SOFTWARE_TOKEN_MFA",
                    );
                }

                const response = await Auth.currentAuthenticatedUser({ bypassCache: true });
                setUser(response);
            } catch (error) {
                parseErrorMessage(error as Error, input, setInput);
            }
        })();
    }

    // Render methods
    function renderVerifyCode(): JSX.Element { return (
        <Fragment>
            <GridTitle
                titleId='auth.enterVerification'
            />
            <GridTextField
                labelId='auth.field.verificationCode'
                value={input.code}
                onChange={handleTextfield('code')}
            />
            <GridActionButton
                labelId='auth.action.sendCode'
                disabled={input.code === ''}
                onClick={handleSubmit}
            />
        </Fragment>
    )}

    function renderSetupTOTP(): JSX.Element {return (
        <Fragment>
            <GridTitle
                titleId='auth.enterVerification'
            />
            <GridTOTPSetupQR user={user}/>
            <GridTextField
                labelId='auth.field.verificationCode'
                value={input.code}
                onChange={handleTextfield('code')}
            />
            <GridActionButton
                labelId='auth.action.sendCode'
                disabled={input.code === ''}
                onClick={handleSubmit}
            />
        </Fragment>
    )}

    switch (authState) {
        case 'MFA_SMS':
        case 'MFA_TOTP':
            return renderVerifyCode();
        case 'MFA_TOTP_ENABLE':
            return renderSetupTOTP();
        case 'MFA_SELECT_TYPE':
        default:
            return ToLoginError({setAuthState: setAuthState});
    }
}
