import { Dispatch, Fragment, MouseEvent, SetStateAction, useState } from "react";
import { AmplifyUser } from "@aws-amplify/ui";

import { Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { AccountCircle, ArrowDropDown } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";

import { MenuSignOut } from "../../authentication";
import { AccountDropdownMenu } from "../../../routing";
import { AuthState } from "../../../utility";
import { PathValues } from "../../../utility/constants";


interface AccountDropdownProps {
    user: AmplifyUser | undefined,
    setAuthState: Dispatch<SetStateAction<AuthState>>,
    setUser: Dispatch<SetStateAction<AmplifyUser | undefined>>,
}

/**
 * Render function for the account dropdown component
 * @param {AmplifyUser?} [AccountDropdownProps.user]    The logged in AWS Amplify user
 * @param {Function} AccountDropdownProps.setAuthState  State setter for authState
 * @param {Function} AccountDropdownProps.setUser       State setter for user
 * @returns {JSX.Element}                               The resulting React Element
 */
export function AccountDropdown(props: AccountDropdownProps): JSX.Element {
    // Destructure props
    const user = props.user;
    const setAuthState = props.setAuthState;
    const setUser = props.setUser;

    // Initialise states
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

    // Set constants
    const theme = useTheme();
    const { t } = useTranslation();
    const listItems = AccountDropdownMenu;
    const navigate = useNavigate();

    // Set UI handlers
    function handleMenu(event: MouseEvent<HTMLElement>) {
        setAnchorElement(event.currentTarget);
    };

    function handleClose() {
        setAnchorElement(null);
    }

    function handleClick(path: PathValues) {
        navigate(path);
        handleClose();
    }

    return (
        <Fragment>
            <Button 
                startIcon={<AccountCircle fontSize="inherit" sx={{marginTop:'-2px'}}/>}
                endIcon={<ArrowDropDown fontSize="inherit" sx={{marginTop:'-2px'}}/>}
                color="inherit"
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
            >
                {user?.attributes?.preferred_username}
            </Button>
            <Menu
                id="menu-appbar"
                anchorEl={anchorElement}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElement)}
                onClose={handleClose}
            >
                {listItems.map(item => (
                    <MenuItem key={t(item.textId)} onClick={() => handleClick(item.link)}>
                        <ListItemIcon sx={{marginRight:'0px'}}>
                            <item.icon sx={{color:theme.palette.primary.light}}/>
                        </ListItemIcon>
                        {t(item.textId)}
                    </MenuItem>
                ))}
                <MenuSignOut setAuthState={setAuthState} setUser={setUser}/>
            </Menu>
        </Fragment>
    );
}
