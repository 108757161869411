export function testPassword(password: string): string[] {
    const errorMessages: string[] = [];

    if (password.length < 8) { 
        errorMessages.push('auth.password.length');
    }
    if (password.toLowerCase() === password) {
        errorMessages.push('auth.password.uppercase');
    }
    if (password.toUpperCase() === password) {
        errorMessages.push('auth.password.lowercase');
    }
    if (!/\d/.test(password)) {
        errorMessages.push('auth.password.number');
    }

    return errorMessages;
}

export const AUTH_STATE = {
    'SIGNIN': 'Sign in',             
    'PASSWORD_FORGOT': 'Forgot password',
    'PASSWORD_RESET': 'Reset password',
    'PASSWORD_NEW': 'New password',
    'MFA_SMS': 'MFA SMS',
    'MFA_TOTP': 'MFA TOTP',
    'MFA_TOTP_ENABLE': 'Enable MFA TOTP',
    'MFA_SELECT_TYPE': 'Select MFA type',
    'SIGNED_IN': 'Signed in',
    'ERROR': 'Error',
} as const;

export type AuthState = keyof typeof AUTH_STATE;

export const ChallengeNameState: { [name: string]: AuthState } = {
    'NONE': 'SIGNED_IN',
    'NEW_PASSWORD_REQUIRED': 'PASSWORD_NEW',
    'MFA_SETUP': 'MFA_TOTP_ENABLE',  // This happens when the MFA method is TOTP and needs to setup the TOTP before using it
    'SELECT_MFA_TYPE': 'MFA_SELECT_TYPE',
    'SMS_MFA': 'MFA_SMS',
    'SOFTWARE_TOKEN_MFA': 'MFA_TOTP',
    'ERROR': 'ERROR'
} as const;
