import { useEffect, useState } from "react";
import { Button, Grid, Typography, Stack, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridTitle } from "./inputs";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { RequestAlert } from "./messages";
import { requestResultParser } from "../../../utility";

import { apiKeyCreate, usersRead, Permissions } from "../../../api/endpoints";

// set up the interfaces for the components
interface APIKeyContainerProps {
    permissions: Permissions;
}
interface CopyableTextProps {
    text: string;
}

interface AlertDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    prefix: string | undefined;
}

/**
 * Render the API Key container
 * @returns {JSX.Element} The resulting React component
 */
export function APIKeyContainer(props: APIKeyContainerProps): JSX.Element {
    // initialize states
    const [key, setKey] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [prefix, setPrefix] = useState(undefined as string | undefined);
    const [message, setMessage] = useState<string>('');

    // initialize translations
    const { t } = useTranslation();

    // fetch the user's token prefix
    useEffect(() => {
        const fetchKey = async (id: number) => {
            const response = await usersRead({ id: id });
            if (response.status === 200 && response.response) {
                setPrefix(response.response[0].tokenPrefix);
            }
        };

        if (props.permissions.userId){
            fetchKey(props.permissions.userId);
            setIsLoading(false);
        }
        
    }, [props]);

    // handle UI events
    const handleClick = () => {
        setDialogOpen(true);
    };
    
    const handleConfirm = async () => {
        setDialogOpen(false);
        setIsLoading(true);
        // make the key HTTP request and set a message for the alert component
        try {
            const response = await apiKeyCreate();
            if (response.status===200){
                const key = await response.response;
                setKey(key || '');
                setPrefix(key?.substring(0, 3) || '');
                setMessage('Key generated successfully!')
            } else {
                if (response?.errorMessage !== undefined) {
                    setMessage(response.errorMessage);
                } else {
                    setMessage('Error generating key!');
                }
            }
        } catch(error) {
            setMessage('Error generating key!')
        }
        setIsLoading(false);
        setDialogOpen(false);
    };

    // copy text to clipboard component
    const CopyableText: React.FC<CopyableTextProps> = ({ text }) => {
        const handleCopy = () => {
            navigator.clipboard.writeText(text);
        };

        return (
            <Stack direction="row" alignItems="center" spacing={1}>
                <Tooltip title="Copy">
                    <IconButton onClick={handleCopy} size="small">
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Typography variant="body2" gutterBottom>
                    {t('api.new.key')}: {text}
                </Typography>
            </Stack>
        );
    };

    // banner that is displayed on top of the page after the HTTP call is made
    function renderMessage(): JSX.Element {
        return (
            <RequestAlert
                messageId={requestResultParser(message)}
                error={!(/success/.test(message))}
                setMessage={setMessage}
            />
        );
    }

    // alert dialog component
    function AlertDialog({ open, onClose, onConfirm, prefix}: AlertDialogProps) {
        return (
            <Dialog
                open={open}
                onClose={onClose}
            >
                <DialogTitle>{t('api.confirmation.title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {prefix ? t('api.confirmation.desc') : t('api.confirmation.desc.no.key')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{t('api.cancel')}</Button>
                    <Button onClick={onConfirm} autoFocus>
                        {t('api.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    // render the component
    return (
        <>
        {renderMessage()}
        <Grid container  id="post-contact">
            <Grid item>
                <Stack direction="row" alignItems="center">
                    <GridTitle titleId='api.title'/>
                    <Typography variant="body2" gutterBottom sx={{ color: 'green', border: 'green 2px solid', borderRadius: "16px", padding:"0px 6px", marginLeft:"4px" }}>
                        Beta
                    </Typography>
                </Stack>
            </Grid>
            <Grid item  >
                
                <Typography variant="body2" gutterBottom>
                    {t('api.desc')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {t('api.desc2')}
                </Typography>
                <Typography variant="body2" gutterBottom sx={{backgroundColor: '#F4F7F9', padding: "8px 4px", borderLeft: "#2F7E98 2px solid"}}>
                {t('api.doc')}
                <a target="_blank" rel="noopener noreferrer" href="https://deepdetector.docs.duckduckgoose.ai/phocus-api/page-2">{t('api.here')}</a>.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ margin: '4px 0px' }}
                    onClick={handleClick}
                    disabled={isLoading} 
                >
                    {t('api.button')}
                </Button>
                <Typography variant="body2" gutterBottom>
                    {
                    prefix === undefined ? t('api.loading'):
                    prefix ? t('api.token.prefix') + prefix + '***' : t('api.no.key')
                    }
                </Typography>
                {
                    key !== '' &&
                    <CopyableText text={key} />
                }
            </Grid>
            <AlertDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onConfirm={handleConfirm}
                prefix={prefix}
            />
        </Grid>
        </>
    );
}
