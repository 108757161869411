import { DashboardOutlined, EmailOutlined, GroupOutlined, Language, MonetizationOnOutlined, SettingsOutlined, TokenOutlined, Api } from "@mui/icons-material";
import { OverridableComponent } from "@mui/types";
import { SvgIconTypeMap } from "@mui/material";

import { PATHS, PathValues } from "../../utility/constants";
import { Role } from "../../api/types";

export const DrawerMenu: {
    link: PathValues,
    textId: string,
    icon: OverridableComponent<SvgIconTypeMap>,
    requiredRole: Role,
}[] = [
    {
        link: PATHS.DASHBOARD,
        textId: 'nav.dashboard',
        icon: DashboardOutlined,
        requiredRole: 'employee',
    },
    {
        link: PATHS.ORGANISATIONS,
        textId: 'nav.organisation',
        icon: Language,
        requiredRole: 'client',
    },
    {
        link: PATHS.USERS,
        textId: 'nav.users',
        icon: GroupOutlined,
        requiredRole: 'client',
    },
    {
        link: PATHS.CREDITS,
        textId: 'nav.credits',
        icon: TokenOutlined,
        requiredRole: 'client',
    },
    {
        link: PATHS.COSTS,
        textId: 'nav.costs',
        icon: MonetizationOnOutlined,
        requiredRole: 'admin',
    },
    {
        link: PATHS.CONTACT,
        textId: 'nav.contact',
        icon: EmailOutlined,
        requiredRole: 'employee',
    },
    {
        link: PATHS.KEY,
        textId: 'nav.api_key',
        icon: Api,
        requiredRole: 'employee',
    },
    /*{
        link: PATHS.SETTINGS,
        textId: 'nav.settings',
        icon: SettingsOutlined,
        requiredRole: 'employee',
    },*/
];
