import { Fragment } from "react";

import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

import { analysisBoundaries } from "../../../utility/constants";


interface MessageComponentProps {
    value: number | string,
}

export function MessageComponent(props: MessageComponentProps): JSX.Element {
    // Destructure props
    const value = props.value;

    // Set constants
    const { t } = useTranslation();

    // Formatting methods
    function formatError(value: string): string {
        return value;
    }

    function formatFakeChance(value: number): string {
        switch (value) {
            case undefined: return '';
            case null: return t('table.values.analysing');
            default: return value.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
        }
    }

    function formatColour(): 'error' | 'info' | 'success' | 'warning' {
        if (typeof value === 'string') {
            return 'info';
        }

        if (value === undefined || value === null) {
            return 'info';
        }

        if (value < analysisBoundaries.NO_DEEPFAKE) {
            return 'success';
        }

        if (value < analysisBoundaries.DEEPFAKE) {
            return 'warning';
        }

        return 'error';
    }

    // Parse props
    const message = (typeof value === 'string' ? formatError(value) : formatFakeChance(value));

    if (message === '') {
        return (<Fragment/>);
    }

    return (
        <Chip
            size="small"
            label={t(message)}
            color={formatColour()}
            sx={{
                paddingTop: '3px',
                minWidth: '60px',
            }}
        />
    );
}
