import { ChangeEvent, Fragment, SyntheticEvent, useEffect, useState } from "react";

import { Button, Dialog, DialogActions, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { GridBackButton, GridCheckBox, GridDateField, GridTextField, GridTitle, TableTitleGrid } from "./inputs";
import { Fields, Order, Table } from "../tables";
import { Credit, creditsCreate, creditsRead, hasRequiredPermission, Organisation, organisationCreditDelete, organisationsRead, Permissions, roles } from "../../../api/endpoints";
import { MESSAGE_OK, PATHS } from "../../../utility/constants";
import { Settings } from '../../../settings';
import dayjs, { Dayjs } from "dayjs";
import { RequestAlert } from "./messages";
import { orderMapping } from "../../../api/orderMapping";
import { requestResultParser } from "../../../utility";


interface CreditContainerProps {
    permissions: Permissions,
    settings: Settings,
    isAdd?: boolean,
    refreshCredits: () => Promise<void>,
}

interface InputState {
    organisationId: number,
    valueOriginal: number,
    valueCurrent: number,
    doesExpire: boolean,
    expireDate: Dayjs,
}

const emptyInput: InputState = {
    organisationId: 1,
    valueOriginal: 0,
    valueCurrent: 0,
    doesExpire: false,
    expireDate: dayjs(),
}

export function CreditContainer(props: CreditContainerProps): JSX.Element {
    // Destructure props
    const permissions = props.permissions;
    const hasPermission = hasRequiredPermission({userRole: permissions?.role, requiredRole: roles.ADMIN});
    const isAdd = (props.isAdd && hasPermission);
    const settings = props.settings;
    const refreshCredits = props.refreshCredits;
    
    const _selected = Number(useParams().id);
    const selected = (!Number.isNaN(_selected)) ? _selected : 0; // 0 if nothing selected

    // Initialise states
    const [credits, setCredits] = useState<Credit[]>([]);
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [count, setCount] = useState<number>(0);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<Fields>('id');
    const [page, setPage] = useState<number>(1);
    const [input, setInput] = useState<InputState>({...emptyInput, organisationId: permissions.organisationId});
    const [message, setMessage] = useState<string>('');
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            await readCredit();

            await setOrganisationsSync();

            if (!selected) { 
                return;
            }

            await setInputSync();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, orderBy, page, selected]);

    // Set constants
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set UI handlers
    function handleTextfield(prop: keyof InputState) {
        return (event: ChangeEvent<HTMLInputElement>) => {
            setInput({ ...input, [prop]: event.target.value });
        };
    }

    function handleDateField(prop: keyof InputState) {
        return (newValue: Dayjs | null) => {
            setInput({ ...input, [prop]: newValue})
        }
    }
    
    function handleCheckbox(prop: keyof InputState) {
        return (event: SyntheticEvent<Element, Event>, checked: boolean) => {
            setInput({ ...input, [prop]: checked });
        };
    }

    function handleSelect(prop: keyof InputState) {
        return (event: SelectChangeEvent<number>, child: React.ReactNode) => {
            const result = event.target.value as number;
            setInput({ ...input, [prop]: result });
        };
    }

    function validateInput(): boolean {
        if (input.valueOriginal <= 0) {
            return false;
        }

        if (input.doesExpire && (input.expireDate === undefined || input.expireDate === null)) {
            return false;
        }

        return true;
    }

    // Set API methods
    async function addCredit() {
        const createResponse = await creditsCreate({
            organisationId: input.organisationId,
            valueOriginal: input.valueOriginal,
            doesExpire: input.doesExpire,
            expireDate: input.doesExpire ? `${input.expireDate.format("YYYY-MM-DD")} 00:00:01` : undefined,
        });

        setInput(emptyInput);

        await readCredit();
        await refreshCredits();

        let message: string = MESSAGE_OK.CREDIT_CREATE;
        if (createResponse.errorMessage !== undefined && createResponse.errorMessage !== '') {
            message = createResponse.errorMessage;
        }
        setMessage(message);
    }

    async function readCredit() {
        const readResponse = await creditsRead({
            orderFields: [`${orderMapping(orderBy, 'credits', order) }`],
            limit: settings.rowsPerPage,
            offset: (page - 1) * settings.rowsPerPage,
        });

        if (readResponse.errorMessage !== undefined && readResponse.errorMessage !== '') {
            setMessage(readResponse.errorMessage);
        }
        setCredits(readResponse.response || []);
        setCount(readResponse.count || 0);
    }

    async function deleteCredit() {
        const deleteResponse = await organisationCreditDelete({id: selected});
        await readCredit();

        let message: string = MESSAGE_OK.CREDIT_DELETE;
        if (deleteResponse.errorMessage !== undefined && deleteResponse.errorMessage !== '') {
            message = deleteResponse.errorMessage;
        }
        setMessage(message);
        navigate(PATHS.CREDITS);
    }

    // Set sync handlers
    async function setInputSync() {
        const creditResponse = await creditsRead({id: selected});
        
        let message = '';
        if (creditResponse.errorMessage !== undefined && creditResponse.errorMessage !== '') {
            message = creditResponse.errorMessage;
        }
        setMessage(message);
        if (!creditResponse.response) {
            return;
        }

        const selectedItem = creditResponse.response[0];

        setInput({
            organisationId: selectedItem.organisationId,
            valueOriginal: selectedItem.valueOriginal,
            valueCurrent: selectedItem.valueCurrent,
            doesExpire: selectedItem.doesExpire,
            expireDate: dayjs(selectedItem.expireDate),
        });
    }

    async function setOrganisationsSync() {
        const organisationsResponse = await organisationsRead({});
        if(!organisationsResponse.response) {
            return;
        }

        setOrganisations(organisationsResponse.response);
    }

    // Render methods

    /**
     * Renders any possible messages that occur due to REST operations
     * @returns {JSX.Element} The resulting React Element
     */
    function renderMessage(): JSX.Element {
        return (
            <RequestAlert
                messageId={requestResultParser(message)}
                error={!(/OK/.test(message))}
                setMessage={setMessage}
            />
        );
    }
    
    /**
     * Renders the UI for adding an entry to the database
     * @returns {JSX.Element} The resulting React Element
     */
    function renderAddContent(): JSX.Element {
        if (!isAdd) {
            return (<Fragment/>);
        }

        return (
            <Grid container spacing={2} id="add-credit" sx={{marginBottom: 2}}>
                <GridTitle titleId='credit.add'/>
                <GridBackButton path={`${PATHS.CREDITS}`}/>
                <Grid item xs={12} lg={4.85}>
                    <FormControl fullWidth>
                        <InputLabel id="label">{t('credit.field.organisation')}</InputLabel>
                        <Select
                            labelId="label"
                            fullWidth
                            size='small'
                            label={t('credit.field.organisation')}
                            value={input.organisationId}
                            onChange={handleSelect('organisationId')}
                        >
                            {organisations.map(item => (
                                <MenuItem value={item.id} key={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <GridTextField
                    labelId='credit.field.valueOriginal'
                    value={input.valueOriginal.toString()}
                    onChange={handleTextfield('valueOriginal')}
                    numeric
                />
                <GridCheckBox
                    labelId='credit.field.doesExpire'
                    value={input.doesExpire}
                    onChange={handleCheckbox('doesExpire')}
                />
                <GridDateField
                    disabled={!input.doesExpire}
                    labelId={'credit.field.expireDate'}
                    value={input.expireDate}
                    onChange={handleDateField('expireDate')}
                />
                <Grid item xs={12} lg={2.3}>
                    <Stack direction="row" justifyContent="end">
                        <Button
                            disabled={!validateInput()}
                            onClick={addCredit}
                            variant='contained'
                            sx={{ fontSize: 14 }}
                        >
                            {t('credit.add')}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
       );
    }
    
    /**
     * Renders the UI for altering an entry in the database
     * @returns {JSX.Element} The resulting React Element
     */
    function renderEditContent(): JSX.Element {
        if (selected === 0) {
            return (<Fragment/>);
        }

        return (
            <Grid container spacing={2} id="edit-credit" sx={{marginBottom: 2}}>
                <GridTitle titleId='credit.edit'/>
                <GridBackButton path={`${PATHS.CREDITS}`}/>
                <Grid item xs={12} lg={4.85}>
                    <FormControl fullWidth>
                        <InputLabel id="label">{t('credit.field.organisation')}</InputLabel>
                        <Select
                            labelId="label"
                            fullWidth
                            disabled
                            size='small'
                            label={t('credit.field.organisation')}
                            value={input.organisationId}
                            onChange={handleSelect('organisationId')}
                        >
                            {organisations.map(item => (
                                <MenuItem value={item.id} key={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <GridTextField
                    disabled
                    labelId='credit.field.valueCurrent'
                    value={input.valueCurrent.toString()}
                    onChange={handleTextfield('valueCurrent')}
                    numeric
                />
                <GridCheckBox
                    disabled
                    labelId='credit.field.doesExpire'
                    value={input.doesExpire}
                    onChange={handleCheckbox('doesExpire')}
                />
                <GridDateField
                    disabled
                    labelId={'credit.field.expireDate'}
                    value={input.expireDate}
                    onChange={handleDateField('expireDate')}
                />
                <Grid item xs={12} lg={2.3}>
                    <Stack direction="row" justifyContent="end">
                        <Button
                            color="error"
                            onClick={() => setDialogOpen(true)}
                            variant='contained'
                            sx={{ fontSize: 14 }}
                        >
                            {t('credit.delete')}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
       );
    }

    /**
     * Renders the deletion dialog
     * @returns {JSX.Element}           The resulting React Element
     */
    function renderDialog(): JSX.Element {
        return (
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            >
                <DialogTitle>
                    {`${t('credit.dialog.delete')}`}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>
                        {t('dialog.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            setDialogOpen(false)
                            deleteCredit();
                        }}
                        autoFocus>
                        {t('dialog.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    /**
     * Renders the title shown above the table
     * @returns {JSX.Element}           The resulting React Element
     */
    function renderTableTitle(): JSX.Element {
        const buttonVisible = 
            hasRequiredPermission({userRole: permissions.role, requiredRole: 'admin'})
            && !isAdd
            && !selected
        ;

        return (
            <TableTitleGrid
                titleId={'credit.title'}
                labelId={'credit.add'}
                buttonVisible={buttonVisible}
                path={`${PATHS.CREDITS}${PATHS.ADD}`}
            />
        );
    }

    /**
     * Renders the table itself
     * @returns {JSX.Element} The resulting React Element
     */
    function renderTable(): JSX.Element {
        return (
            <Table
                fields={['id', 'organisationName', 'valueOriginal', 'valueCurrent', 'expireDate']}
                rows={credits}
                rowsPerPage={settings.rowsPerPage}
                count={count}
                order={order}
                orderBy={orderBy}
                page={page}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setPage={setPage}
                endpoint={PATHS.CREDITS}
            />
        );
    }

    return (
        <Fragment>
            {renderMessage()}
            {renderAddContent()}
            {renderEditContent()}
            {renderTableTitle()}
            {renderTable()}
            {renderDialog()}
        </Fragment>
    );
}
