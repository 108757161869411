import { Fragment } from 'react';

import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getImageExtensions } from '../../../utility';


interface StorageObject {
    itemName: string,
    url: string,
    extension: string,
}

interface FileCardProps {
    object: StorageObject,
    nameId: string,
}

interface FilesComponentProps {
    urls: string[],
}

interface FilteredKeys {
    original?: StorageObject,
    overlay?: StorageObject,
    highlight?: StorageObject,
}

function downloadBlob(url: string, filename: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
}

/**
 * Render function that supplies a clickable card to download storage images
 * @param {StorageObject}   props.object
 *                          The storage item to represent
 * @param {string}          props.nameId
 *                          String source id for what the item should be called
 * @returns {JSX.Element}   The resulting React Element
 */
export function FileCard(props: FileCardProps): JSX.Element {
    // Destructure props
    const url = props.object.url;
    const itemName = props.object.itemName;
    const fileExtension = props.object.extension;
    const nameId = props.nameId;

    // Set constants
    const { t } = useTranslation();

    // Set API functions
    async function onClick() {
        downloadBlob(url, itemName);
    }

    // Render methods

    /**
     * Renders the image
     * @returns {JSX.Element} The resulting React Element
     */
    function renderMedia(): JSX.Element {
        if (fileExtension === undefined) {
            return <Fragment/>
        }

        const allowedExtensions = getImageExtensions();
        if (!allowedExtensions.includes(fileExtension)) {
            return <Fragment/>
        }

        return (
            <CardMedia
                component="img"
                src={url}
            />
        );
    }
    
    return (
        <Card>
            <CardActionArea onClick={onClick}>
                { renderMedia() }
                <CardContent>
                    <Typography>
                        {`${t('record.item.download')} ${t(nameId)}`}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

/**
 * Render function that supplies a clickable card to download storage images
 * @param {string}          props.fileName
 *                          The file that we show the results of
 * @returns {JSX.Element}   The resulting React Element
 */
export function FilesComponent(props: FilesComponentProps): JSX.Element {
    // Destructure props
    const urls = props.urls;

    // Set constants
    const storageObjects: StorageObject[] = [];
    for (const url of urls) {
        const temp = (/public.*\?/.exec(url) ?? [''])[0];
        storageObjects.push({
            url: url,
            itemName: (/.*\/(.*)\.(.*)/.exec(temp) ?? ['', ''])[1],
            extension: (/\.(.*?)\?/.exec(temp) ?? ['', ''])[1],
        });
    }

    function buildItem(
        item: StorageObject | undefined,
        nameId: string,
        xs: number,
        md: number,
        lg: number,
    ): JSX.Element {
        if (item === undefined) {
            return <Fragment/>;
        }

        return (
            <Grid item xs={xs} md={md} lg={lg}>
                <FileCard
                    object={item}
                    nameId={nameId}
                />
            </Grid>
        );
    }

    /**
     * Render method that shows only the relevant items to the user
     */
    function filteredItems(): JSX.Element {
        const filteredItems: FilteredKeys = {}
        for (const storageObject of storageObjects) {

            if (storageObject.itemName === 'original') { filteredItems.original = storageObject; }
            if (storageObject.itemName === 'overlay') { filteredItems.overlay = storageObject; }
            if (storageObject.itemName === 'highlight') { filteredItems.highlight = storageObject; }
        }

        return (
            <Fragment>
                { buildItem(filteredItems.original, 'record.item.original', 12, 6, 4) }
                { buildItem(filteredItems.overlay, 'record.item.overlay', 12, 6, 4) }
                { buildItem(filteredItems.highlight, 'record.item.highlight', 12, 12, 4) }
            </Fragment>
        );
    }

    return filteredItems();
}
